import React, { useState } from "react";
import {
    useDataGrid,
    EditButton,
    ShowButton,
    List,
    TagField,
    EmailField,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps } from "@refinedev/core";
import { CustomAuthLayer } from "components/layouts/authLayer";
import { reponsiveWidth } from "components/utils/styles";
import { Box, Button } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";

export const UserList: React.FC<IResourceComponentsProps> = () => {
    const [ pagesize, setPagesize] = useState(0)
    const [startValue, setStartValue] = useState('') 
    const [ records_length, setRecordsLength] = useState(0)
    const { dataGridProps } = useDataGrid({
        meta: {
            headers: { pagesize, startValue, 'special-access-token':process.env.REACT_APP_X_Access },
        }
    });

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "id",
                headerName: "Id",
                minWidth: 50,
            },
            {
                field: "username",
                flex: 1,
                headerName: "Username",
                minWidth: 200,
            },
            {
                field: "status",
                flex: 1,
                headerName: "Status",
                minWidth: 200,
            },
            {
                field: "roles",
                flex: 1,
                headerName: "Roles",
                minWidth: 200,
                renderCell: function render({ row }) {
                    return (
                        <>
                            {row?.roles?.map((item: any) => (
                                <TagField value={item} key={item} />
                            ))}
                        </>
                    );
                },
            },
            {
                field: "email",
                flex: 1,
                headerName: "Email",
                minWidth: 250,
                renderCell: function render({ value }) {
                    return <EmailField value={value} />;
                },
            },
            {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <EditButton hideText recordItemId={row.id} />
                            <ShowButton hideText recordItemId={row.id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],
        [],
    );

    return (
        <CustomAuthLayer role={'ROLE_ADMIN'}>
            <List>
                <DataGrid {...dataGridProps} columns={columns} hideFooterPagination={true} autoHeight style={{overflow:'auto', maxWidth:reponsiveWidth}}  
                    onStateChange={(state) => {
                        setStartValue(state.rows.dataRowIdToIdLookup[Object.keys(state.rows.dataRowIdToIdLookup)[Object.keys(state.rows.dataRowIdToIdLookup).length-1]])
                        setRecordsLength(Object.keys(state.rows.dataRowIdToIdLookup).length)
                    }}  
                />
                <Box style={{display:'flex', justifyContent:'flex-end'}}>
                    {pagesize !== 0 && <Button onClick={() => setPagesize(pagesize - 10)} startIcon={<ArrowBack />}></Button>}
                    {records_length > 9 && <Button onClick={() => setPagesize(pagesize + 10)} startIcon={<ArrowForward />}></Button>}
                </Box>
            </List>
        </CustomAuthLayer>
    );
};
