import { useShow, IResourceComponentsProps, useMany } from "@refinedev/core";
import {
    Show,
    TextFieldComponent as TextField,
    TagField,
} from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getTomorrow } from "components/utils/utils";

export const PayoutShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow({
        meta: {
            headers: { 'special-access-token':process.env.REACT_APP_X_Access }
        },});
    const { data, isLoading } = queryResult;

    const record = data?.data;

    let navigate = useNavigate()

    const { data: transactionsData, isLoading: transactionsIsLoading } =
        useMany({
            resource: "transactions",
            ids: record?.transactions || [],
            queryOptions: {
                enabled: !!record && !!record?.transactions?.length,
            },
            meta: {
                headers: { 'special-access-token':process.env.REACT_APP_X_Access }
            },
        });

    return (
        <Show isLoading={isLoading}>
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    Transactions
                </Typography>
                {transactionsIsLoading && record?.transactions?.length ? (
                    <>Loading...</>
                ) : (
                    record?.transactions.map((item:any, index:any) => typeof item === 'object' ?  <TagField key={index} onClick={() => navigate('/transactions/show/'+Object.values(item))} style={{maxWidth:'fit-content'}} value={Object.values(item) } /> : <TagField key={index} style={{maxWidth:'fit-content'}} value={item} />)
                )}
                <Typography variant="body1" fontWeight="bold">
                    Status
                </Typography>
                <TextField value={record?.status} />
                <Typography variant="body1" fontWeight="bold">
                    Amount
                </Typography>
                <TextField value={record?.currency === "USD" ? Number(record?.amount).toLocaleString('en-US') : record?.amount} />
                <Typography variant="body1" fontWeight="bold">
                    Currency
                </Typography>
                <TextField value={record?.currency} />
                <Typography variant="body1" fontWeight="bold">
                    Created At: 
                </Typography>
                <TextField value={new Date(record?.createdAt).toLocaleString()} />
                {record?.currency === "BTC" &&
                    <>
                        <Typography variant="body1" fontWeight="bold">
                            Cancels At: 
                        </Typography>
                        <TextField value={getTomorrow(record?.createdAt)} />
                    </>
                }
                <Typography variant="body1" fontWeight="bold">
                    Updated At
                </Typography>
                <TextField value={new Date(record?.updatedAt).toDateString()} />
                <Typography variant="body1" fontWeight="bold">
                    Id
                </Typography>
                <TextField value={record?.id} />
            </Stack>
        </Show>
    );
};
