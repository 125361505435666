import { useMany, useOne } from "@refinedev/core";
import { grey } from '@mui/material/colors';
import { useState } from 'react'
import { Typography, AppBar, Toolbar, IconButton, MenuItem, Menu, Avatar, Dialog, DialogTitle, DialogContent, ListItemButton, ListItemText, ListItemIcon, Alert, Box, Container, Button, LinearProgress, DialogContentText, DialogActions } from "@mui/material";
import { AccountBalance, CurrencyBitcoin, Dashboard, GroupAdd, Groups3, Logout, MenuOpen, Receipt } from '@mui/icons-material';
import { useThemedLayoutContext } from "@refinedev/mui";
import { useLocation, useNavigate } from "react-router-dom";
import { useLogout } from "@refinedev/core";
import { UserContext, UserDispatchContext } from "contexts/auth.context";
import { useContext, useEffect } from 'react'
import { style } from "./utils/styles";
import { InlineInquiry } from "./accounts/Persona";
import { CreateACHRecipient } from "./ACH";
import SlideInDialog from "./SlideIn.Dialog";
import axios from "axios";

export const CustomHeader = (props:any) => {
    const {
        siderCollapsed,
        setSiderCollapsed,
    } = useThemedLayoutContext();

    let navigate = useNavigate()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [dialogOpen, setDialogOpen] = useState(false)
    const [activateAccountDialog, setAADialog] = useState(false)
    const [personaDialog, setPDialog] = useState(false)
    const [openACHDialog, setOpenACH] = useState(false)

    const open = Boolean(anchorEl);

    //set user details
    const storedUser = localStorage.getItem('user')
    const user = storedUser && JSON.parse(storedUser)
    const location = useLocation();

    const { data:updated_user, isLoading } = useOne<any>({
      resource: "users",
      id:user?.id,
      queryOptions: {
          staleTime: Infinity
      }
    });

    const setUserDetails = useContext(UserDispatchContext);
    const usercontexted = useContext(UserContext)
    

    useEffect(() => {
      setUserDetails(updated_user?.data)
    }, [updated_user])
    //set user details

    const { mutate: logout } = useLogout();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
      setDialogOpen(false)
    };

    const handleCollaspe = () => {
      if(window.innerWidth < 900){
        setDialogOpen(true)
      }
        if(siderCollapsed){
            setSiderCollapsed(false)
        } else {
            setSiderCollapsed(true)
        }
    }

    const [alerts, setAlerts] = useState<any>([])

    const { data:aches } = useMany({
        resource: "ach/user/"+user?.id,
        ids:[],
        meta: {
            headers: { 'special-access-token':process.env.REACT_APP_X_Access }
        },
    });

    const ach = aches?.data ?? []
    

    useEffect(() => {

      let localAlerts:any = []
      if(usercontexted?.status === "not_activated"){
        localAlerts.push({content:'You have not activated your account', function:() => setAADialog(true)})
      }
      if(!usercontexted?.kyc){
        localAlerts.push({content:'You have not done your KYC', function: () => setPDialog(true)})
      }

      if(ach?.length  < 1 ){
        localAlerts.push({content:'You have not uploaded an ACH payout', function: () => setOpenACH(true)})
      }

      setAlerts(localAlerts)
    }, [usercontexted, updated_user, ach])

    const handleVerify = (stripe_account_id:any) => {
      axios.post(process.env.REACT_APP_API+"/user/kyc/account/verify", {account_id:stripe_account_id, meta:{attempt:new Date().toLocaleDateString()}} )
      .then((res) => {
          console.log(res);
      })
      .catch((error) => {
          // here you will have access to error.response
          console.log(error.response)
      });
    }

    useEffect(() => {
      if(!updated_user?.data.kyc){
        setTimeout(() => {
          handleVerify(updated_user?.data.stripe_account_id)
        }, 1000);
      }
    }, [])
    
    return (
    <>
    <AppBar position="static">
    <Toolbar>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{ mr: 2 }}
        onClick={handleCollaspe}
      >
        <MenuOpen />
      </IconButton>
      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
        
      </Typography>
      {user && (
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleClick}
            color="inherit"
          >
            <Avatar sx={{ bgcolor: grey[800] }}>{user?.name?.slice(0, 2) || user?.email?.slice(0, 2)}</Avatar>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={() => {
              navigate('/users/show/'+user?.id)
              handleClose()
              }}>Profile</MenuItem>
            <MenuItem onClick={() => {
              navigate('/users/edit/'+user?.id)
              handleClose()
              }}>Settings</MenuItem>
          </Menu>
        </div>
      )}
    </Toolbar>
    <Dialog
        //fullScreen={fullScreen}
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" style={{textAlign:'center'}}>
          <img src="https://www.cryptocashfast.com/logo512.png" width={50} />
        </DialogTitle>
        <DialogContent>

          <ListItemButton onClick={() => {
            navigate('/dashboard')
            setDialogOpen(false)
            }} component="a" href="#simple-list">
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
          
          <ListItemButton onClick={() => {
            navigate('/buy-bitcoin')
            setDialogOpen(false)}} component="a" href="#simple-list">
            <ListItemIcon>
              <CurrencyBitcoin />
            </ListItemIcon>
            <ListItemText primary="Buy Bitcoin" />
          </ListItemButton>

          <ListItemButton onClick={() => {
            navigate('/payouts')
            setDialogOpen(false)}} component="a" href="#simple-list">
            <ListItemIcon>
              <AccountBalance />
            </ListItemIcon>
            <ListItemText primary="Balance" />
          </ListItemButton>
          
          <ListItemButton onClick={() => {
            navigate('/transactions')
            setDialogOpen(false)}} component="a" href="#simple-list">
            <ListItemIcon>
              <Receipt />
            </ListItemIcon>
            <ListItemText primary="Transactions" />
          </ListItemButton>
          
          <ListItemButton onClick={() => {
            navigate('/wallets')
            setDialogOpen(false)}} component="a" href="#simple-list">
            <ListItemIcon>
              <GroupAdd />
            </ListItemIcon>
            <ListItemText primary="Wallets" />
          </ListItemButton>
          
          {user?.roles?.includes('ROLE_ADMIN') &&
            <ListItemButton onClick={() => {
              navigate('/users')
              setDialogOpen(false)}} component="a" href="#simple-list">
              <ListItemIcon>
                <Groups3 />
              </ListItemIcon>
              <ListItemText primary="Users" />
            </ListItemButton>
          }
          
          <ListItemButton onClick={() => {
            logout()
            setDialogOpen(false)}} component="a" href="#simple-list">
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText primary="Log out" />
          </ListItemButton>

        </DialogContent>
      </Dialog>
  </AppBar>
  {alerts.length > 0 &&
    <Container style={{zIndex:1000}}>
      {alerts?.map((item:any, index:any) =>
        <Alert style={{margin:5}} severity="error" key={index}>{item.content} - <Button onClick={item.function}>Fix it!</Button></Alert>
      )}
      <SlideInDialog 
        open={activateAccountDialog}
        setOpen={setAADialog}
        title={"Check your inbox and spambox"} 
        content={"Go to your email box to find your activation link."} 
        buttonTitle1={"Close"} 
        buttonFunciton1={() => setAADialog(false)} 
      />
      <InlineInquiry open={personaDialog} setOpen={setPDialog} />
      <CreateACHRecipient open={openACHDialog} handleClose={() => setOpenACH(false)} />
    </Container>
  }
  {isLoading &&
    <Box sx={[style, {textAlign:'center'}]}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Fetching account details
      </Typography>
      <LinearProgress />
    </Box>
  }
  {alerts.length > 0 && !location.pathname.includes("/users/show") && !location.pathname.includes("/users/edit") && !location.pathname.includes("kyc-verify")  &&
    <Container 
      style={{
        backgroundColor:'white', 
        height:'100vh', 
        position:'fixed', 
        zIndex:100,
        marginTop:80,
        minWidth: '100%',
      }}
    ></Container>
  }
  </>
);
}