import { Alert, Button, Card, Checkbox, Container, FormControlLabel, FormGroup, Stack, TextField, Typography } from "@mui/material";
import { useRegister } from "@refinedev/core";
import { Logo } from "components/logo";
import { useState } from 'react'

export const RegisterPage = () => {
    const { mutate: register } = useRegister<any>();
    
    const [checked, setChecked] = useState(true);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked);
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!checked){
          alert('You must agree to terms and conditions')
          return 
        }
        const values = {
            email: e.currentTarget.email.value,
            password: e.currentTarget.password.value,
        };

        register(values);
        //console.log(values)
    };

    return (
      <Container style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
        <Stack spacing={2}>
        <Logo />
  
        <Alert style={{minWidth:300, maxWidth:500}} severity="warning">After you finish registering, remember to check both your email inbox and spam folder for the activation link.</Alert>
        <Card style={{minWidth:300, padding:20, maxWidth:500}}>
          <Typography style={{textAlign:'center', fontFamily:'"Roboto","Helvetica","Arial",sans-serif', fontWeight:700}} variant="h5" color="primary">Register for an account.</Typography>
        <form onSubmit={onSubmit}>
        <Stack spacing={2}>
            <FormGroup>
              <TextField type="email" style={{margin:10}} placeholder="email" name="email" required />
              <TextField type="password" style={{margin:10}}  placeholder="password" name="password" required />
              <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} />} label={<a href="https://info.cryptocashfast.com/user-terms-of-service">I agree to the User Terms of Service</a>} />
              
            </FormGroup>
            <Button type="submit" variant="contained">Create Account</Button>
            <Container>
              <Typography style={{textAlign:'center'}}>Already have an account? <a style={{textDecoration:'none', color:'#e83a18'}} href="/login">Sign In</a></Typography>
            </Container>
        </Stack>
        </form>
        </Card>
        </Stack>
      </Container>
    );
};
