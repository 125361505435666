import { Box, Button, Card, CardActions, CardContent, Typography } from "@mui/material";

export const APIDocsWidget = () => {
    
    return (
    <Box 
        sx={{
            maxWidth: 300,
            marginBottom:5
        }}
    >
        <Card sx={{ minWidth: 250 }}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                API integration doc
                </Typography>
                <Typography variant="h5" component="div">
                API documentation
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                Bitcoin payment integration
                </Typography>
                <Typography variant="body2">
                Create BTC payment gateways
                <br />
                {'Easy setup.'}
                </Typography>
            </CardContent>
            <CardActions>
                <a href="https://docs.cryptocashfast.com/" target="_blank"><Button size="small">View More</Button></a>
            </CardActions>
        </Card>
    </Box>
    )
}