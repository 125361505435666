import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { Stack } from '@mui/material';
import Container from '@mui/material/Container';
import { useMany, useOne } from "@refinedev/core";
import { TotalBalanceWidget } from 'components/Widgets/total.widget';
import { RecentTransactionsWidget } from 'components/Widgets/recent.transactions';
import { APIDocsWidget } from 'components/Widgets/api.docs';
import { HelpWidget } from 'components/Widgets/help.widget';
import { GettingStarted } from 'components/getting-started-steps';
import { UserContext } from 'contexts/auth.context';
import { GettingStartedWidget } from 'components/Widgets/getting_started.widget';

export const Item = ({children}:any) => {
    return (
        <div>{children}</div>
    )
}

export default function DashboardHome() {
    const identity = React.useContext(UserContext)

    const [achID, setACHID] = React.useState<any>(false)
    const [achDetails, setAchDetails] = React.useState<any>([])
    const [countLoads, setCountLoads] = React.useState(0)

    const { data:aches } = useMany({
      resource: "ach",
      ids:[],
      meta: {
          headers: { 'special-access-token':process.env.REACT_APP_X_Access }
      },
    });

    const { data:user } = useOne({
      resource: "users",
      id:identity?.id,
      meta: {
          headers: { 'special-access-token':process.env.REACT_APP_X_Access }
      },
    });

    const ach = aches?.data

    React.useEffect(() => {

      setCountLoads(countLoads + 1)
      ach?.filter(item => {
        if(item?.user === user?.data?.id){
          setAchDetails([item])
          setACHID(false)
        }
      })
    }, [aches, user])

    React.useEffect(() => {
      if(countLoads > 1 && achDetails?.length < 1){
        setACHID(true)
      } else {
        setACHID(false)
      }
    }, [achDetails, countLoads])

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg">
        <Stack style={{display:'flex', justifyContent:'center'}} spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
            <APIDocsWidget />
            <TotalBalanceWidget view_more={true} />
            <GettingStartedWidget />
            <HelpWidget />
            <RecentTransactionsWidget />
        </Stack>
      </Container>
      
      <GettingStarted />
    </React.Fragment>
  );
}