import React, { useContext, useState } from "react";
import {
    useDataGrid,
    ShowButton,
    List,
    TagField,
    DateField,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps, useMany } from "@refinedev/core";
import { Box, Button, Stack } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { reponsiveWidth } from "components/utils/styles";
import { TotalBalanceWidget } from "components/Widgets/total.widget";
import { UserContext } from "contexts/auth.context";

export const PayoutList: React.FC<IResourceComponentsProps> = () => {
    const [ pagesize, setPagesize] = useState(0)
    const [ records_length, setRecordsLength] = useState(0)
    const [startValue, setStartValue] = useState('') 
    const user = useContext(UserContext)
    const { dataGridProps } = useDataGrid({
        meta: {
            headers: { business: user?.id, pagesize, startValue, 'special-access-token':process.env.REACT_APP_X_Access },
        }
    });

    const { data: transactionsData, isLoading: transactionsIsLoading } =
        useMany({
            resource: "transactions",
            ids: [].concat(
                ...(dataGridProps?.rows?.map(
                    (item: any) => item?.transactions,
                ) ?? []),
            ),
            queryOptions: {
                enabled: !!dataGridProps?.rows,
            },
            meta: {
                headers: {
                    "Wallet": user?.address,
                    'special-access-token':process.env.REACT_APP_X_Access,
                },
            }
        });

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "id",
                headerName: "Id",
                minWidth: 50,
            },
            {
                field: "transactions",
                flex: 1,
                headerName: "Transactions",
                minWidth: 100,
                renderCell: function render({ value }) {
                    return transactionsIsLoading ? (
                        <>Loading...</>
                    ) : (
                        <>
                            {value?.map((item: any, index: number) => (
                                <TagField key={index} value={typeof item === 'object' ? Object.values(item) : item} />
                            ))}
                        </>
                    );
                },
            },
            {
                field: "status",
                flex: 1,
                headerName: "Status",
                minWidth: 50,
            },
            {
                field: "amount",
                flex: 1,
                headerName: "Amount",
                type: "number",
                minWidth: 50,
                renderCell: function render({ value }) {
                    var amount = value.split('.')
                    if (amount[0].length > 1) {
                        // Too many numbers before decimal.
                        return Number(value).toLocaleString('en-US');
                    }

                    return Number(value).toFixed(8);
                    
                },
            },
            {
                field: "currency",
                flex: 1,
                headerName: "Currency",
                minWidth: 100,
                renderCell: function render({ value }) {
                    if(value === "BTC"){
                        return <img width={50} src="https://storage.googleapis.com/cryptocashfast.appspot.com/Bitcoin.png" />
                    }
                    if(value === "USD"){
                        return <img width={50} src="https://storage.googleapis.com/cryptocashfast.appspot.com/usd.png" />
                    }
                },
            },
            {
                field: "createdAt",
                flex: 1,
                headerName: "Created At",
                minWidth: 100,
                renderCell: function render({ value }) {
                    return <DateField value={new Date(value)} />;
                },
            },
            {
                field: "updatedAt",
                flex: 1,
                headerName: "Updated At",
                minWidth: 100,
                renderCell: function render({ value }) {
                    return <DateField value={new Date(value)} />;
                },
            },
            {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <ShowButton hideText recordItemId={row.id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],
        [transactionsData?.data],
    );

    return (
        <List>
            <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                <TotalBalanceWidget />
            </Stack>
            <DataGrid {...dataGridProps} columns={columns} hideFooterPagination={true} autoHeight style={{overflow:'auto', maxWidth:reponsiveWidth}}  
                onStateChange={(state) => {
                    setStartValue(state.rows.dataRowIdToIdLookup[Object.keys(state.rows.dataRowIdToIdLookup)[Object.keys(state.rows.dataRowIdToIdLookup).length-1]])
                    setRecordsLength(Object.keys(state.rows.dataRowIdToIdLookup).length)
                }}  
            />
            <Box style={{display:'flex', justifyContent:'flex-end'}}>
                {pagesize !== 0 && <Button onClick={() => setPagesize(pagesize - 10)} startIcon={<ArrowBack />}></Button>}
                {records_length > 9 && <Button onClick={() => setPagesize(pagesize + 10)} startIcon={<ArrowForward />}></Button>}
            </Box>
        </List>
    );
};
