import { Box, Typography, Button, TextField, Container, Tabs, Tab } from "@mui/material";
import { IResourceComponentsProps, useUpdate, useOne } from "@refinedev/core";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SlideInDialog from "components/SlideIn.Dialog";
import { UserContext } from "contexts/auth.context";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        key={index}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

export const CryptoTransactionCreate: React.FC<
    IResourceComponentsProps
> = () => {

    const { mutate } = useUpdate();

    const bitcoinToUsd = (bitcoin_amount:any, usdCrytoRate:any) => {
        let total = bitcoin_amount * usdCrytoRate; 
        return total.toFixed(2)
     }

    const [wallet_address, setWalletAddress] = useState('')
    const [currency_amount, setCurrencyAmount] = useState(.034920943)
    const [btcRate, setBTCRate] = useState(0)
    const [usd_amount, setUSDAmount] = useState<any>(0)
    const [fee, setFee] = useState<any>(0)
    const [howToCreateWallet, setHowToCreateWallet] = useState(false)
    const [currencyType, setCurrencyType] = useState('BTC')

    const user = useContext(UserContext)

    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    let keys = [
        {label:"Bitcoin Amount Estimate", value:currency_amount, setValue:setCurrencyAmount, variant:true},
        {label:"USD Cost Estimate", value:usd_amount, setValue:setUSDAmount, variant:true},
        {label:"Fee Estimator", value:fee, setValue:setFee, variant:false},
    ]

    let wireDomesticKeys = [
        {label:"Beneficiary Name", value:"SoxNationApp, Inc."},
        {label:"Account Number", value:"202321218001"},
        {label:"Type of Account", value:"Checking"},
        {label:"Beneficiary Address", value:"5815 Gablewood Way, San Diego, CA 92130"},
        {label:"ABA Routing Number", value:"091311229"},
        {label:"Bank Name", value:"Choice Financial Group"},
        {label:"Bank Address", value:"4501 23rd Avenue S Fargo, ND 58104"},
        {label:"Memo", value:user?.wire_code},
    ]

    let wireInterKeys = [
        {label:"SWIFT / BIC Code", value:"CHFGUS44021"},
        {label:"ABA Routing Number", value:"091311229"},
        {label:"Bank Name", value:"Choice Financial Group"},
        {label:"Bank Address", value:"4501 23rd Avenue S Fargo, ND 58104 USA"},
        {label:"IBAN / Account Number", value:" 202321218001"},
        {label:"Beneficiary Name ", value:"SoxNationApp, Inc."},
        {label:"Beneficiary Address", value:"5815 Gablewood Way, San Diego, CA 92130 USA"},
        {label:"Memo", value:user?.wire_code},
    ]

    const handleBuyBitcoin = async () => {
        try {
          const wallet_details = await axios.get('https://api.blockcypher.com/v1/btc/main/addrs/'+wallet_address+'/balance')
          if(wallet_details){
            mutate({
                resource: "users",
                values: {
                    wallet: wallet_address,
                },
                id: user?.id,
                meta: {
                    headers: { 'special-access-token':process.env.REACT_APP_X_Access }
                },
            });
          }
        } catch (error) {
            return alert('Not a found wallet')
        }
    }

    const getBTCRate = async() => {
        const response = await axios.get("https://api.coincap.io/v2/assets/?ids=bitcoin")
        setBTCRate(response?.data.data[0].priceUsd)
    }
    const { data } = useOne<any>({
        resource: "users",
        id:user?.id,
        meta: {
            headers: { 'special-access-token':process.env.REACT_APP_X_Access }
        },
    });

    useEffect(() => {
        getBTCRate()
    }, [])

    useEffect(() => {
        let usd = Number(bitcoinToUsd(currency_amount, btcRate))
        let local_fee = usd * .03
        let local_total =  usd + local_fee
        if(currencyType === "BTC"){
            setUSDAmount(local_total.toFixed(2))
        } else {
            setCurrencyAmount(usd_amount / btcRate)
        }
    }, [currency_amount, btcRate, usd_amount])

    useEffect(() => {
        setFee((usd_amount * .03).toFixed(2))
    }, [usd_amount])
    
  const [gettingStarted, setGetStart] = useState(false)
  let navigate = useNavigate()

  useEffect(() => {
    if(user.getting_started){
        setTimeout(() => {
            setGetStart(true)
        }, 1000);
    }
  }, [])

    return (
        <>
        <Container>
            <Typography variant="h5">
                Make a deposit using one of the following ways.
            </Typography>
            {data?.data.wallet ?
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Wire Instructions" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <Typography>
                    Crypto Cash Fast, Inc.
                    Domestic Transfer Details
                    </Typography>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        {wireDomesticKeys.map((item, index) =>
                            <TextField style={item.label === 'Memo' && howToCreateWallet ? {backgroundColor:'yellow'} : {}} multiline key={index} id="outlined-basic" label={item.label} value={item.value} variant="outlined" />
                        )}
                    </Box>
                    <Typography>
                    Crypto Cash Fast, Inc.
                    International Wire Details
                    </Typography>
                    <Box
                        component="form"
                        sx={{
                            '& > :not(style)': { m: 1, width: '25ch' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        {wireInterKeys.map((item, index) =>
                            <TextField style={item.label === 'Memo' && howToCreateWallet ? {backgroundColor:'yellow'} : {}} multiline key={index} id="outlined-basic" label={item.label} value={item.value} variant="outlined" />
                        )}
                    </Box>
                </CustomTabPanel>
            </Box>
            :
            <>
            <Typography variant="h4">
                Deposit wallet
            </Typography>
            <Typography variant="body1">
                Your BTC balance will be deposited into the following account.
            </Typography>
            <Typography variant="h6">
                BTC Deposit Wallet: {data?.data.wallet}
            </Typography>
            <Container>
                <TextField 
                    id="outlined-basic" 
                    onChange={(e:any) => setWalletAddress(e.target.value)} 
                    label={'Wallet Address'} 
                    value={wallet_address}
                    variant={'outlined'} 
                    style={{margin:5}}
                />
            </Container>

            <Container style={{marginBottom:10}}>
                <Button variant="contained" onClick={handleBuyBitcoin}>Add Wallet</Button>
            </Container>
            </>
            }
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column", textAlign:'center'}}
                autoComplete="off"
            >
                <Container>
                    <Typography variant="h5">
                        Get a quick estimate!
                    </Typography>
                    {keys.map((item, index) => 
                        <TextField 
                            key={index} 
                            id="outlined-basic" 
                            onChange={(e:any) => {
                                item.setValue(e.target.value)
                                if(item.label === 'USD Cost Estimate'){
                                    setCurrencyType('USD')
                                } else {
                                    setCurrencyType('BTC')
                                }
                            }} 
                            label={item.label} 
                            value={item.value}
                            variant={item.variant ? 'outlined' : "standard" } 
                            style={{margin:5}}
                            disabled={item.variant ? false : true } 
                        />
                    )}
                </Container>
            </Box>
        </Container>

        
        <SlideInDialog 
            type={'getting_started'}
            open={gettingStarted} 
            setOpen={setGetStart} 
            title={"Buy Bitcoin"} 
            content={
                <>
                    <Typography variant="body1">Your wire code can be found on this page, with the rest of the details you need in order to wire funds to CCF to purchase BTC. </Typography> 
                    <Typography style={{marginTop:10, marginBottom:10}}> Any wires that you send using your assigned code in the memo space for wires, will be credited to your account.</Typography>
                    <Button onClick={() => {
                        setHowToCreateWallet(true)
                        setGetStart(false)
                    }} style={{paddingLeft:0}}>View Your Wire Wire Code</Button>
                    <Typography variant="h6" style={{marginTop:20, marginBottom:10}}> Wire Code example.</Typography>
                </>
                } 
            example={<TextField label="Memo" value={user?.wire_code} />}
            buttonTitle2={"Next Lesson : Learn how to convert BTC to USD"} 
            buttonFunciton2={() => {
                setGetStart(false)
                navigate('/wallets')
            }}
        />
        </>
    );
};
