import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Close } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { useUpdate } from "@refinedev/core";
import { UserContext } from 'contexts/auth.context';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SlideInDialog(props:any) {
  const { example, open, setOpen, title, content, buttonTitle1, buttonTitle2, buttonFunciton1, buttonFunciton2, type} = props

  const user = React.useContext(UserContext)

  const { mutate } = useUpdate();

  const handleEndTutorial = () => {
    setOpen(false);
    mutate({
      resource: "users",
      values: {
          getting_started: false
      },
      id: user?.id,
      meta: {
          headers: { 'special-access-token':process.env.REACT_APP_X_Access }
      },
    });
  }

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{title}</DialogTitle>
        {type === "getting_started" &&
          <Button
            aria-label="close"
            onClick={handleEndTutorial}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            endIcon={<Close />}
          >
            <Typography>End Tutorial</Typography>
          </Button>
        }
        <DialogContent>
          {content}
          {example}
        </DialogContent>
        <DialogActions>
          <Button onClick={buttonFunciton1}>{buttonTitle1}</Button>
          {buttonTitle2 && <Button onClick={buttonFunciton2}>{buttonTitle2}</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}