import { Edit, ListButton } from "@refinedev/mui";
import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useMany } from "@refinedev/core";
import { AdminPanelSettings, Business, Person, SupervisorAccount } from "@mui/icons-material";
import { CustomAuthLayer } from "components/layouts/authLayer";
import { useUpdate } from "@refinedev/core";
import { useState } from 'react'
import { CreateACHRecipient } from "components/ACH";
import { InlineInquiry } from "components/accounts/Persona";

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
    const {
        saveButtonProps,
        refineCore: { queryResult },
        register,
        control,
        formState: { errors },
    } = useForm();

    const usersData = queryResult?.data?.data;

    const [username, setUsername] = useState<any>()
    const [email, setEmail] = useState<any>()
    const [password, setPassword] = useState<any>()
    const [webhook, setWebhook] = useState<any>()
    const [open, setOpen] = useState(false)
    const [openPersona, setOpenPersona] = useState(false) 

    const handleClose = () => {
        if(open){
            setOpen(false)
        } else {
            setOpen(true)
        }
    }

    const { mutate } = useUpdate();

    const handleUpdate = () => {
        mutate({
            resource: "users",
            values: {
                username,
                email,
                password,
                webhook,
            },
            id: usersData?.id || '',
            meta: {
                headers: { 'special-access-token':process.env.REACT_APP_X_Access }
            },
        });

    }

    const { data:aches } = useMany({
        resource: "ach/user/"+usersData?.id,
        ids:[],
        meta: {
            headers: { 'special-access-token':process.env.REACT_APP_X_Access }
        },
    });

    const ach = aches?.data

    return (
        <CustomAuthLayer id={usersData?.id}>
        <Edit 
            breadcrumb={false} 
            saveButtonProps={saveButtonProps}
            headerButtons={({ defaultButtons }) => (
                <>
                    {usersData?.roles?.includes('614b954d3e09732573ccd312') &&
                        <ListButton />
                    }
                </>
            )}
            footerButtons={({saveButtonProps}) => (
                
                    <Button onClick={handleUpdate}>
                        Update
                    </Button>
            )}
        >
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            >
                <TextField
                    {...register("id", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.id}
                    helperText={(errors as any)?.id?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Id"
                    name="id"
                    disabled
                />
                <TextField
                    {...register("username", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.username}
                    helperText={(errors as any)?.username?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Username"
                    name="username"
                    onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                    {...register("email", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.email}
                    helperText={(errors as any)?.email?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    {...register("password", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.password}
                    helperText={(errors as any)?.password?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="password"
                    label="Password"
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                    {...register("status", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.status}
                    helperText={(errors as any)?.status?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="Status"
                    name="status"
                    disabled
                />
                <Divider style={{margin:20}} />
                <Box sx={{ display: "flex", gap: 1 }}>
                    <Typography>Roles:</Typography>
                    {usersData?.roles?.map((item: any, index: number) => {
                        switch(item){
                            case "614b954d3e09732573ccd312":
                            return <Typography key={index}> <AdminPanelSettings />Admin</Typography>
                            case "614b954d3e09732573ccd310":
                            return <Typography key={index}> <Person />User</Typography>
                            case "614b954d3e09732573ccd311":
                            return <Typography key={index}> <SupervisorAccount />Moderator</Typography>
                            case "637798f62ef110ab1bf1dd53":
                            return <Typography key={index}> <Business />Business</Typography>
                        }
                    })}
                </Box>
                <Divider style={{margin:20}} />
                <Typography variant="h5">Webhook</Typography>
                <TextField
                    {...register("webhook", {
                        required: "This field is required",
                    })}
                    error={!!(errors as any)?.webhook}
                    helperText={(errors as any)?.webhook?.message}
                    margin="normal"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    type="text"
                    label="HTTPS url"
                    name="webhook"
                    onChange={(e) => setWebhook(e.target.value)}
                />
                
                <Typography variant="body1" fontWeight="bold">
                    ACH Payout
                </Typography>
                
                {ach?.slice(0,1).map((item:any, index:any) => 
                    <Box key={index}>
                        <Typography style={{fontWeight:600}}>{item.name}
                        </Typography>
                        <Typography>{"Ref ID: "+item.recipientId}
                        </Typography>
                    </Box>
                )}
            </Box>
            <Button style={{padding:0}} onClick={() => setOpen(true)}>Create a new ACH Profile</Button>
            <CreateACHRecipient open={open} handleClose={handleClose} />
            <Box sx={{ marginTop:1, gap: 1 }}>
                <Typography variant="body1" fontWeight="bold">
                    KYC Verification
                </Typography>
                {!usersData?.kyc ?
                    <>
                        <Button style={{padding:0}} onClick={() => setOpenPersona(true)}>Create KYC profile</Button>
                        <InlineInquiry open={openPersona} setOpen={setOpenPersona} />
                    </>
                    :
                    <Typography>
                        Ref ID: {usersData?.kyc}
                    </Typography>
                }
            </Box>
        </Edit>
        </CustomAuthLayer>
    );
};
