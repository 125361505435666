import { Create } from "@refinedev/mui";
import { Box, Button, Container, Typography } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useCreate } from "@refinedev/core";
import { MetaController } from "components/metaController";
import { useState, useEffect, useContext } from 'react'
import { Wallet } from "@mui/icons-material";
import SlideInDialog from "components/SlideIn.Dialog";
import { useNavigate } from "react-router-dom";
import { UserContext } from "contexts/auth.context";

export const WalletCreate: React.FC<IResourceComponentsProps> = () => {
    const {
        refineCore: { formLoading }
    } = useForm();

    const { mutate } = useCreate();

    const [key, setKey] = useState<any>([])
    
    const identity = useContext(UserContext)

    const handleSubmit = () => {
        mutate({
            resource: "wallets",
            values: {
                auth: identity?.id,
                meta: key,
            },
            meta: {
                headers: { 'special-access-token':process.env.REACT_APP_X_Access }
            },
        });
    }

    const [gettingStarted, setGetStart] = useState(false)
    let navigate = useNavigate()
  
    useEffect(() => {
        if(identity.getting_started){
            setTimeout(() => {
                setGetStart(true)
            }, 1000);
        }
    }, [])

    return (
        <>
        <Create 
            title={<Typography variant="h5">Wallets</Typography>}
            isLoading={formLoading} 
            footerButtons={({defaultButtons}) => (
            <></>
            )}
        >
            <Container style={{textAlign:'center'}}>
                <Typography variant="h4">
                    Create a Bitcoin wallet.
                </Typography>
                <Typography>
                    All Bitcoin sent to this wallet will be paid out to your ACH payout account in USD.
                </Typography>
                <MetaController remotekey={key} setNewKey={setKey} />

                <Button style={{margin:10}} onClick={handleSubmit} variant="contained" startIcon={<Wallet />}>Create A Wallet</Button>
            </Container>
        </Create>
        
        <SlideInDialog 
            type={'getting_started'}
            open={gettingStarted} 
            setOpen={setGetStart} 
            title={"Convert BTC to USD"} 
            content={
                <>
                    <Typography variant="h6">
                        Now you can create a wallet
                    </Typography>
                    <Typography>
                        Any coins sent to any wallets you create under your account will be converted to USD and sent to your ACH payout account. First create a wallet.
                    </Typography>
                    <br />
                    <Typography variant="h6">
                        Metadata (or metainformation). A set of data that describes and gives information about other data.
                    </Typography>
                    <Typography>
                        You can add metadata about your wallet using the "Key" and "Value" fields. You can add as much or as little metadata you'd like for each wallet. You can edit this data later.
                    </Typography>
                    <br />
                    <Button onClick={() => {
                        setGetStart(false)
                    }} style={{paddingLeft:0}}>
                        Step Two : Create the Wallet
                    </Button>
                </>
            }
            buttonTitle1={"Next Lesson : Learn how to Buy Bitcoin"} 
            buttonFunciton1={() => {
                setGetStart(false)
                navigate('/buy-bitcoin')
            }}
        />
        </>
    );
};
