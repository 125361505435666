import { Box, Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import { useList } from "@refinedev/core";
import { UserContext } from "contexts/auth.context";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const TotalBalanceWidget = (props:any) => {

    const { view_more } = props

    const [usdTotal, setUSDTotal] = useState(0)
    const [btcTotal, setBTCTotal] = useState(0)

    let key = [
        {currency:"BTC"},
        {currency:"USD"}
    ]
    
    const user = useContext(UserContext)

    let navigate = useNavigate()

    const { data, isLoading, isError } = useList({
        resource: "payouts",
        meta: {
            headers: { business: user?.id, listedlimit:0, 'special-access-token':process.env.REACT_APP_X_Access },
        }
    });

    const payouts = data?.data ?? []

    useEffect(() => {
      let pendingUSDTotal:any = []
      let pendingBTCTotal:any = []
      payouts.filter(item => item.status === "Pending" && item.currency === "USD" && pendingUSDTotal.push(Number(item.amount)))
      payouts.filter(item => item.status === "Pending" && item.currency === "BTC" && pendingBTCTotal.push(Number(item.amount)))
      let USDtotal = pendingUSDTotal.reduce((a:any, b:any) => a + b, 0)
      let BTCtotal = pendingBTCTotal.reduce((a:any, b:any) => a + b, 0)
      setUSDTotal(USDtotal.toFixed(2))
      setBTCTotal(BTCtotal.toFixed(5))
    }, [data])
    

    return (
    <>
        {key.map((item:any, index:any) =>
            <Box 
                key={index}
                sx={{
                    maxWidth: 300,
                    marginBottom:5
                }}
            >
                <Card sx={{ minWidth: 250 }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Current {item.currency} Balance
                        </Typography>
                        <Typography variant="h5" component="div">
                        {item.currency === "BTC" ? Number(btcTotal).toLocaleString('en-US', { maximumSignificantDigits: 20 }) : Number(usdTotal).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        {item.currency} Payout
                        </Typography>
                        <Typography variant="body2">
                        {item.currency} payouts appear within 
                        <br />
                        {item.currency === "BTC" ? '24 hours' : '1 - 4 days.'}
                        </Typography>
                    </CardContent>
                    {view_more &&
                        <CardActions>
                            <Button onClick={() => navigate('/payouts')} size="small">View More</Button>
                        </CardActions>
                    }
                </Card>
            </Box>
        )}
    </>
    )
}