import { useShow, IResourceComponentsProps, useMany } from "@refinedev/core";
import {
    Show,
    TextFieldComponent as TextField,
    EditButton,
    DeleteButton,
} from "@refinedev/mui";
import { Typography, List, ListSubheader, ListItemButton, ListItemIcon, ListItemText, Collapse, Container } from "@mui/material";
import { CustomAuthLayer } from "components/layouts/authLayer";
import { AccountBalance, AdminPanelSettings, ArrowRight, Cancel, CheckCircle, Key, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useContext, useState } from "react";
import { reponsiveWidth } from "components/utils/styles";
import { UserContext } from "contexts/auth.context";

export const UserShow: React.FC<IResourceComponentsProps> = () => {
    const user = useContext(UserContext)
    const { queryResult } = useShow({
        meta: {
            headers: { 'special-access-token':process.env.REACT_APP_X_Access }
        },});
    const { data, isLoading } = queryResult;

    const record = data?.data;

    const { data:aches } = useMany({
        resource: "ach/user/"+user?.id,
        ids:[],
        meta: {
            headers: { 'special-access-token':process.env.REACT_APP_X_Access }
        },
    });

    const ach = aches?.data

    const [openACH, setOpenA] = useState(false);
    const [openRoles, setOpenR] = useState(false);
    const [openToken, setOpenT] = useState(false);

    let listStyle = { width: reponsiveWidth, maxWidth:700, margin:'auto', bgcolor: 'background.paper' }

    return (
        <CustomAuthLayer id={record?.id}>
        <Show breadcrumb={false} isLoading={isLoading} headerButtons={({ defaultButtons }) => (
                <>
                    <EditButton />
                    <DeleteButton />
                </>
            )}>
            
            <List
                sx={listStyle}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                    Personal Details
                    </ListSubheader>
                }
            >
                {[
                    {primary:"ID", secondary:record?.id, icon:<CheckCircle />},
                    {primary:"Username", secondary:record?.username && record?.username, icon:record?.username ? <CheckCircle /> : <Cancel color="error" />},
                    {primary:"Status", secondary:record?.status, icon:record?.status === "not_activated" ? <Cancel color="error" /> : <CheckCircle />},
                    {primary:"Email", secondary:record?.email, icon:<CheckCircle />},
                ].map((item:any, index:any) =>
                    <ListItemButton key={index}>
                        <ListItemIcon>
                        {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.primary} />
                        <ListItemText secondary={item.secondary} />
                    </ListItemButton>
                )}

                <ListItemButton onClick={() => setOpenA(!openACH)}>
                    <ListItemIcon>
                    <AccountBalance />
                    </ListItemIcon>
                    <ListItemText primary="ACH Payout" />
                    {openACH ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                </ListItemButton>
                <Collapse in={openACH} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                    {ach?.slice(0,1).map((item:any, index:any) => 
                        <ListItemButton key={index} sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <ArrowRight />
                            </ListItemIcon>
                            <ListItemText primary={"Ref ID: "+item.recipientId} />
                            <ListItemText secondary={item.name} />
                        </ListItemButton>
                    )}
                    </List>
                </Collapse>
                    
                <ListItemButton onClick={() => setOpenR(!openRoles)}>
                    <ListItemIcon>
                    <AdminPanelSettings />
                    </ListItemIcon>
                    <ListItemText primary="Roles" />
                    {openRoles ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                </ListItemButton>
                <Collapse in={openRoles} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {record?.roles?.map((item: any) => (
                            <ListItemButton key={item} sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <ArrowRight />
                                </ListItemIcon>
                                {item === '637798f62ef110ab1bf1dd53' && <ListItemText primary={'Business'} />}
                                {item === '614b954d3e09732573ccd312' && <ListItemText primary={'Admin'} />}
                            </ListItemButton>
                        ))}
                    </List>
                </Collapse>
            </List>
            
            <List
                sx={listStyle}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                    Verification
                    </ListSubheader>
                }
            >
                {[
                    {primary:"KYC", secondary:record?.kyc ?? "No KYC" , icon:record?.kyc ? <CheckCircle /> : <Cancel color="error" />},
                ].map((item:any, index:any) =>
                    <ListItemButton key={index}>
                        <ListItemIcon>
                        {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.primary} />
                        <ListItemText secondary={item.secondary} />
                    </ListItemButton>
                )}
            </List>
            
            <List
                sx={listStyle}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                    Wallet Details
                    </ListSubheader>
                }
            >
                {[
                    {primary:"Wallet Address", secondary:record?.wallet , icon:record?.wallet ? <CheckCircle /> : <Cancel color="error" />},
                ].map((item:any, index:any) =>
                    <ListItemButton key={index}>
                        <ListItemIcon>
                        {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.primary} />
                        <ListItemText secondary={item.secondary} />
                    </ListItemButton>
                )}
            </List>

            
            <List
                sx={listStyle}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                    Developer Details
                    </ListSubheader>
                }
            >
                {[
                    {primary:"Auth ID", secondary:record?.id, icon:<CheckCircle />},
                ].map((item:any, index:any) =>
                    <ListItemButton key={index}>
                        <ListItemIcon>
                        {item.icon}
                        </ListItemIcon>
                        <ListItemText style={{wordWrap:'break-word'}} primary={item.primary} />
                        <ListItemText style={{wordWrap:'break-word'}} secondary={item.secondary} />
                    </ListItemButton>
                )}
                
                <ListItemButton onClick={() => setOpenT(!openToken)}>
                    <ListItemIcon>
                    <Key />
                    </ListItemIcon>
                    <ListItemText primary="Access Token" />
                    {openToken ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                </ListItemButton>
                <Collapse in={openToken} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <Container style={{wordWrap:'break-word'}}>

                        <Typography>
                        {user?.accessToken}
                       </Typography>
                        </Container>
                    
                    </List>
                </Collapse>
            </List>
        </Show>
        </CustomAuthLayer>
    );
};
