import { Container } from "@mui/material"
import { UserContext } from "contexts/auth.context"
import { useContext, useEffect, useState } from 'react'

export const CustomAuthLayer = (props:any) => {
    const { children, role, id } = props
    const identity = useContext(UserContext)
    const [show, setShow] = useState(false)

    useEffect(() => {
        if(role){
            if(identity?.roles?.includes(role)){
                setShow(true)
            } else {
                setShow(false)
            }
        }

      if(id){
        if(id === identity?.id){
            setShow(true)
        } else {
            setShow(false)
        }
      }

      if(identity?.roles?.includes("614b954d3e09732573ccd312")){
        setShow(true)
      }
    }, [props])
    
    
    return (
        <Container>
            {show ? 
                children 
                : 
                <Container style={{height:'100vh', display:'flex', alignItems:'center', justifyContent:'center'}}>
                    You are not authorized to view this page
                </Container>
            }
        </Container>
    )
}