import { Card, CardContent, Container, Grid, Typography } from "@mui/material"
import { useList } from "@refinedev/core";
import { CryptoBalances } from "components/admin/crypto.balance";

export const CCFBalances = () => {

    const { data:ccf_bank_data, isLoading, isError } = useList({
        dataProviderName: "admin",
        resource: "bank/ccf/balance",
        queryOptions: {
            staleTime: Infinity
        },
        meta: {
            headers: { 'special-access-token':process.env.REACT_APP_X_Access }
        },
    });

    const { data:sox_bank_data } = useList({
        dataProviderName: "admin",
        resource: "bank/sox/balance",
        queryOptions: {
            staleTime: Infinity
        },
        meta: {
            headers: { 'special-access-token':process.env.REACT_APP_X_Access }
        },
    });

    const ccf_bank = ccf_bank_data?.data;

    const sox_bank = sox_bank_data?.data

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Something went wrong!</div>;
    }

    return (
        <Container>
            <Grid container spacing={2}>
                <CryptoBalances slice={4} />
                {ccf_bank?.map((item, index) =>
                <Grid key={index} item>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {item.name}
                            </Typography>
                            <Typography variant="h5" component="div">
                            Balance: {"$"+Number(item.availableBalance).toFixed(2).toLocaleString()}
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            {item.legalBusinessName}
                            </Typography>
                            <Typography variant="body2">
                            Account Number: {item.accountNumber}
                            <br />
                            Type: {item.kind}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                )}
                {sox_bank?.map((item, index) =>
                <Grid key={index} item>
                    <Card sx={{ minWidth: 275 }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            {item.name}
                            </Typography>
                            <Typography variant="h5" component="div">
                            Balance: {"$"+Number(item.availableBalance).toFixed(2).toLocaleString()}
                            </Typography>
                            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                            {item.legalBusinessName}
                            </Typography>
                            <Typography variant="body2">
                            Account Number: {item.accountNumber}
                            <br />
                            Type: {item.kind}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                )}
            </Grid>
        </Container>
    )
}