import { OpenInNew } from "@mui/icons-material";
import { Box, Button, Card, CardActions, CardContent, Typography } from "@mui/material";

export const HelpWidget = () => {
    
    return (
    <Box 
        sx={{
            maxWidth: 300,
            marginBottom:5
        }}
    >
        <Card sx={{ minWidth: 250 }}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Help Center
                </Typography>
                <Typography variant="h5" component="div">
                Need help? <a href="https://cryptocashfast.tawk.help/" target="_blank"><OpenInNew /></a>
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                Bitcoin payment integration
                </Typography>
                <Typography variant="body2">
                Start accepting BTC payments
                <br />
                {'Easy setup.'}
                </Typography>
            </CardContent>
        </Card>
    </Box>
    )
}