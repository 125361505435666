import React, { useState } from "react";
import {
    useDataGrid,
    List,
    DateField,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps } from "@refinedev/core";
import { Box, Button, Modal, Typography, Chip } from "@mui/material";
import { ArrowBack, ArrowForward, Visibility } from "@mui/icons-material";
import { reponsiveWidth, style } from "components/utils/styles";
import { useNavigate } from "react-router-dom";

export const AllTransactionList: React.FC<IResourceComponentsProps> = () => {
    const [ pagesize, setPagesize] = useState(0)
    const [ records_length, setRecordsLength] = useState(0)
    const [startValue, setStartValue] = useState('') 
    const [open, setOpen] = useState(false)
    const [address, setaddress] = useState('')
    let navigate = useNavigate()
    const { dataGridProps } = useDataGrid({
        resource: "crypto_transactions/admin",
        meta: {
            headers: { pagesize, startValue, 'special-access-token':process.env.REACT_APP_X_Access }
        },
    });

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "original_hash",
                headerName: "Original Hash",
                minWidth: 50,
                renderCell: function render({ value }) {
                    if(value){
                        return ("..."+value.slice(55,))
                    }
                },
            },
            {
                field: "details",
                headerName: "Hash",
                minWidth: 50,
                renderCell: function render({ value }) {
                    return (
                        "..."+value.hash.slice(55,)
                    )
                },
            },
            {
                field: "wallet",
                headerName: "Addresses",
                minWidth: 220,
                renderCell: function render({ value }) {
                    return (
                        <Button onClick={() => {
                            setaddress(value)
                            setOpen(true)
                            }}>
                            {value[0].slice(0,3)+" ... "+value[0].slice(31)} 
                            <ArrowForward />
                            {value[1].slice(0,3)+" ... "+value[1].slice(31)} 
                        </Button>
                    )
                },
            },
            {
                field: "currency_amount",
                flex: 1,
                headerName: "BTC Amount",
                minWidth: 100,
                renderCell: function render({ value }) {
                    return Number(value).toFixed(8)
                },
            },
            {
                field: "currency",
                flex: 1,
                headerName: "Currency",
                minWidth: 100,
                renderCell: function render({ value }) {
                    if(value === "Bitcoin"){
                        return <img width={50} src="https://storage.googleapis.com/cryptocashfast.appspot.com/Bitcoin.png" />
                    }
                    if(value === "USD"){
                        return <img width={50} src="https://storage.googleapis.com/cryptocashfast.appspot.com/usd.png" />
                    }
                },
            },
            {
                field: "payment_status",
                flex: 1,
                headerName: "Payment Status",
                minWidth: 100,
            },
            {
                field: "payout_status",
                flex: 1,
                headerName: "Payout Status",
                minWidth: 100,
            },
            {
                field: "usd_amount",
                flex: 1,
                headerName: "USD Amount",
                minWidth: 100,
                renderCell: function render({ value }) {
                    return "$"+Number(value).toFixed(2).toLocaleString();
                },
            },
            {
                field: "updatedAt",
                flex: 1,
                headerName: "Updated At",
                minWidth: 100,
                renderCell: function render({ value }) {
                    return <DateField value={new Date(value)} />;
                },
            },
            {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <Button onClick={() => navigate("/transactions/show/"+row.id)}><Visibility /></Button>
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],
        [],
    );

    return (
        <List>
            <DataGrid {...dataGridProps} columns={columns} hideFooterPagination={true} autoHeight style={{maxWidth:reponsiveWidth, overflow:'auto', margin:'auto'}}  
                onStateChange={(state) => {
                    setStartValue(state.rows.dataRowIdToIdLookup[Object.keys(state.rows.dataRowIdToIdLookup)[Object.keys(state.rows.dataRowIdToIdLookup).length-1]])
                    setRecordsLength(Object.keys(state.rows.dataRowIdToIdLookup).length)
                }}  
            />
            <Box style={{display:'flex', justifyContent:'flex-end'}}>
                {pagesize !== 0 && <Button onClick={() => setPagesize(pagesize - 10)} startIcon={<ArrowBack />}></Button>}
                {records_length > 9 && <Button onClick={() => setPagesize(pagesize + 10)} startIcon={<ArrowForward />}></Button>}
            </Box>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={[style, {textAlign:'center'}]}>
                    <Typography>
                        From:
                    </Typography>
                    <Typography>
                    <Chip label={address[0]} />
                    </Typography>
                    <ArrowForward color="primary" />
                    <Typography>
                        To:
                    </Typography>
                    <Typography>
                        <Chip label={address[1]} />
                    </Typography>
                </Box>
            </Modal>
        </List>
    );
};