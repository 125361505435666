import { Container } from "@mui/material";

export const Logo = () => {
    return (
        <Container style={{display:'flex', justifyContent:'center'}}>
            <a href="/" style={{textDecoration:'none'}}>
                <img src="https://www.cryptocashfast.com/logo512.png" width={150} />
            </a>
        </Container>
    )
}