import { useShow, IResourceComponentsProps } from "@refinedev/core";
import {
    Show,
    TextFieldComponent as TextField,
    DateField,
} from "@refinedev/mui";
import { Typography, Stack } from "@mui/material";

export const FailedTransactionShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow({
        meta: {
            headers: { 'special-access-token':process.env.REACT_APP_X_Access }
        },});
    const { data, isLoading } = queryResult;

    const record = data?.data;

    return (
        <Show isLoading={isLoading}>
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    Wallet
                </Typography>
                {record?.wallet.map((value:any) => value+", ")}
                <Typography variant="body1" fontWeight="bold">
                    Original Hash
                </Typography>
                {record?.original_hash}
                <Typography variant="body1" fontWeight="bold">
                    Error Details
                </Typography>
                <Typography style={{maxWidth:'100%', wordBreak:'break-all'}}>
                    {JSON.stringify(record?.details)}
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                    Payment Status
                </Typography>
                <TextField value={record?.payment_status} />
                <Typography variant="body1" fontWeight="bold">
                    Payout Status
                </Typography>
                <TextField value={record?.payout_status} />
                <Typography variant="body1" fontWeight="bold">
                    Payout Currency
                </Typography>
                <TextField value={record?.currency} />
                <Typography variant="body1" fontWeight="bold">
                    BTC Amount
                </Typography>
                <TextField value={record?.currency_amount} />
                <Typography variant="body1" fontWeight="bold">
                    USD Amount
                </Typography>
                <TextField value={"$"+Number(record?.usd_amount).toLocaleString()} />
                <Typography variant="body1" fontWeight="bold">
                    Created At
                </Typography>
                <TextField value={new Date(record?.createdAt).toDateString()} />
                <Typography variant="body1" fontWeight="bold">
                    Updated At
                </Typography>
                <TextField value={new Date(record?.updatedAt).toDateString()} />
                <Typography variant="body1" fontWeight="bold">
                    Id
                </Typography>
                <TextField value={record?.id} />
            </Stack>
        </Show>
    );
};
