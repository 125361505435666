import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { style } from 'components/utils/styles';
import { useUpdate } from "@refinedev/core";
import { useContext, useState } from 'react';
import { UserContext } from 'contexts/auth.context';
import axios from 'axios';
import { Button, Typography } from '@mui/material';

export const InlineInquiry = (props:any) => {
  const { open, setOpen } = props
  const handleClose = () => setOpen(false);
  const user = useContext(UserContext)
  const [ copyUrl, setCopyUrl ] = useState('')

  const getStripeKYC = async () => {
    const stripe = await axios.get(process.env.REACT_APP_API+"/user/kyc/"+user.stripe_account_id)
    
    if(stripe.data.url){
        setCopyUrl(stripe.data.url)
        window.open(stripe.data.url, "_blank")
    }
  }

  return (
    <>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >

            <Box sx={[style]}>
                <Typography>
                    Crypto Cash Fast, Inc. uses Stripe for KYC. You will be redirected
                    back here once you finish.
                </Typography>
                <Button variant='contained' fullWidth onClick={getStripeKYC}>Complete KYC via Stripe</Button>
                
                
                {copyUrl &&
                    <Typography style={{wordWrap: 'break-word', marginTop:20, backgroundColor:'yellow', padding:10}}>
                        You can copy and past this URL into your 
                        browser in order to complete your KYC verificaiton : {copyUrl}
                    </Typography>
                }
            </Box>
        </Modal>
    </>
	);
};
