import { useShow, IResourceComponentsProps } from "@refinedev/core";
import {
    Show,
    TextFieldComponent as TextField,
} from "@refinedev/mui";
import { Typography, Stack, Container, Backdrop, Button } from "@mui/material";
import { useState, useEffect, useContext } from 'react'
import SlideInDialog from "components/SlideIn.Dialog";
import { useNavigate } from "react-router-dom";
import { UserContext } from "contexts/auth.context";

export const WalletShow: React.FC<IResourceComponentsProps> = () => {
    const { queryResult } = useShow({
        meta: {
            headers: { 'special-access-token':process.env.REACT_APP_X_Access }
        },});
    const { data, isLoading } = queryResult;
    const user = useContext(UserContext)

    const record = data?.data[0];

    let navigate = useNavigate()
    
    const [howToCreateWallet, setHowToCreateWallet] = useState(false)
    const [gettingStarted, setGetStart] = useState(false)

    useEffect(() => {
        if(user.getting_started){
            setTimeout(() => {
                setGetStart(true)
            }, 1000);
        }
    }, [])

    return (
        <>
        <Show canDelete isLoading={isLoading}>
            <Stack gap={1}>
                <Container style={howToCreateWallet ? {zIndex:10000, backgroundColor:'yellow', padding:10, borderRadius:5} :{margin:0, padding:0}}>
                   
                    {howToCreateWallet &&
                    <>
                    <Typography variant="h6">
                        Address details
                    </Typography>
                    <Typography variant="body1">
                        You can use the address or wallet to send your Bitcoin to.
                        <br />
                        <i>Any coins sent to this wallet will be converted to USD and sent to your ACH payout account.</i>
                    </Typography>
                    </>
                    }
                    <Container style={{padding:10, margin: 0, backgroundColor:'white'}}>
                        <Typography variant="body1" fontWeight="bold">
                            Address
                        </Typography>
                        <TextField value={record?.address ?? "Creating wallet address, this can take up to 3 minutes."} />
                    </Container>
                </Container>
                <Typography variant="body1" fontWeight="bold">
                    Wallets
                </Typography>
                <TextField value={record?.wallet} />
                <Typography variant="body1" fontWeight="bold">
                    Meta
                </Typography>
                <Container><pre id="json">{JSON.stringify(record?.meta, null, 3)}</pre></Container>
                <Typography variant="body1" fontWeight="bold">
                    Created At
                </Typography>
                <TextField value={new Date(record?.createdAt).toDateString()} />

                <Typography variant="body1" fontWeight="bold">
                    Updated At
                </Typography>
                <TextField value={new Date(record?.updatedAt).toDateString()} />

                <Typography variant="body1" fontWeight="bold">
                    Id
                </Typography>
                <TextField value={record?.id} />
            </Stack>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={howToCreateWallet}
                onClick={() => setHowToCreateWallet(false)}
            >
            </Backdrop>
        </Show>
        <SlideInDialog 
            type={'getting_started'}
            open={gettingStarted} 
            setOpen={setGetStart} 
            title={"Convert BTC to USD"} 
            content={
                <>
                    <Typography variant="h6">
                        Send Bitcoin to your wallet
                    </Typography>
                    <Typography>
                        Any coins sent to any wallets you create under your account will be converted to USD and sent to your ACH payout account.
                    </Typography>
                    <Button onClick={() => {
                        setHowToCreateWallet(true)
                        setGetStart(false)
                    }} style={{paddingLeft:0}}>
                        Last step : send coins to wallet address
                    </Button>
                </>
            }
            buttonTitle1={"Next Lesson : Learn how to buy Bitcoin"} 
            buttonFunciton1={() => {
                setGetStart(false)
                navigate('/buy-bitcoin')
            }}
        />
    </>
    );
};
