import React, { createContext, useEffect, useState } from "react";

// Create two context:
// UserContext: to query the context state
// UserDispatchContext: to mutate the context state
const UserContext = createContext<any>(undefined);
const UserDispatchContext = createContext<any>(undefined);

// A "provider" is used to encapsulate only the
// components that needs the state in this context
function UserProvider({ children }:any) {
  const [userDetails, setUserDetails] = useState({});

  return (
    <UserContext.Provider value={userDetails}>
      <UserDispatchContext.Provider value={setUserDetails}>
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
}

export { UserProvider, UserContext, UserDispatchContext };