import { Button, Card, Container, FormGroup, Stack, TextField, Typography } from "@mui/material";
import { Logo } from "components/logo";
import { useCustomMutation } from "@refinedev/core";
import { useParams } from "react-router-dom";

export const PasswordUpdate = () => {
    const { token } = useParams()
    const { mutate } = useCustomMutation();

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let pass1 = e.currentTarget.password.value
        let pass2 = e.currentTarget.confirmPassword.value
        if (pass1 !== pass2){
            return alert('Passwords do not match')
        }

        mutate({
            method: "put",
            url: "https://api.cryptocashfast.com/password/reset?token=" + token,
            values: {
                password: e.currentTarget.password.value,
                confirm_password: e.currentTarget.confirmPassword.value,
            },
            successNotification: (data, values, resource) => {
                if(data?.data?.result === "error"){
                    return {
                        message: data?.data?.message,
                        description: `Something went wrong.`,
                        type: "error",
                    };

                }
                
                return {
                    message: `Successfully Upldated.`,
                    description: "Success with no errors",
                    type: "success",
                };
            },
            errorNotification: (data, values, resource) => {
                return {
                    message: `Something went wrong.`,
                    description: "Error"+data?.response?.data,
                    type: "error",
                };
            },
        });
    };

    return (
      <Container style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
        <Stack spacing={2}>
        <Logo />
  
        <Card style={{minWidth:300, padding:20, maxWidth:500}}>
          <Typography style={{textAlign:'center', fontFamily:'"Roboto","Helvetica","Arial",sans-serif', fontWeight:700}} variant="h5" color="primary">Set New Password</Typography>
        <form onSubmit={onSubmit}>
        <Stack spacing={2}>
            <FormGroup>
              <TextField type="password" style={{margin:10}} placeholder="New Password" name="password" required />
              <TextField type="password" style={{margin:10}}  placeholder="New Password" name="confirmPassword" required />
              
            </FormGroup>
            <Button type="submit" variant="contained">Update Password</Button>
            <Container>
              <Typography style={{textAlign:'center'}}>Already have an account? <a style={{textDecoration:'none', color:'#e83a18'}} href="/login">Sign In</a></Typography>
            </Container>
        </Stack>
        </form>
        </Card>
        </Stack>
      </Container>
    );
};