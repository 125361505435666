import { Edit, TextFieldComponent as TextField, DateField } from "@refinedev/mui";
import { Box, Container, Typography, Stack, Button } from "@mui/material";
import { useForm } from "@refinedev/react-hook-form";
import { IResourceComponentsProps, useUpdate } from "@refinedev/core";
import { useEffect, useState } from "react";
import { MetaController } from "components/metaController";
import { useParams } from "react-router-dom";

export const WalletEdit: React.FC<IResourceComponentsProps> = () => {
    const {
        refineCore: { queryResult }
    } = useForm();

    const walletsData = queryResult?.data?.data[0];

    const { mutate } = useUpdate();

    const [key, setKey] = useState()

    const { id } = useParams()

    const handleUpdate = () => {
        mutate({
            resource: "wallets",
            values: {
                meta: key,
            },
            id:id ?? '',
            meta: {
                headers: { 'special-access-token':process.env.REACT_APP_X_Access }
            },
        });
    }

    useEffect(() => {
      setKey(walletsData?.meta)
    }, [walletsData])

    return (
        <Edit 
            footerButtons={({defaultButtons}) => (
                <></>
            )}
        >
            <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column" }}
                autoComplete="off"
            ></Box>
            
            <Stack gap={1}>
                <Typography variant="body1" fontWeight="bold">
                    Address
                </Typography>
                <TextField value={walletsData?.address} />
                <Typography variant="body1" fontWeight="bold">
                    Wallets
                </Typography>
                <TextField value={walletsData?.wallet} />
                <Typography variant="body1" fontWeight="bold">
                    Meta
                </Typography>
                <Container><pre id="json">{JSON.stringify(walletsData?.meta, null, 3)}</pre></Container>
                <MetaController remotekey={key} setNewKey={setKey} />
                <Container>
                    <Button variant="contained" onClick={handleUpdate}>Update Meta</Button>
                </Container>
                <Typography variant="body1" fontWeight="bold">
                    Created At
                </Typography>
                <DateField value={walletsData?.createdAt} />
                <Typography variant="body1" fontWeight="bold">
                    Updated At
                </Typography>
                <DateField value={walletsData?.updatedAt} />
                <Typography variant="body1" fontWeight="bold">
                    Id
                </Typography>
                <TextField value={walletsData?.id} />
            </Stack>
        </Edit>
    );
};
