import { Hub, Memory, PointOfSale, ShoppingCart } from "@mui/icons-material";
import { Container, Grid, Typography } from "@mui/material";
import ButtonAppBar from "./Navigation";


export default function WelcomePage() {
    const header = {backgroundColor:'aliceblue', padding:30, marginTop:50, display:'flex', alignItems:'center'}

    return (
        <>
            <ButtonAppBar />
            <Container>
                <Grid>
                    <Grid style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <img 
                            src="https://www.cryptocashfast.com/logo512.png" 
                            alt="Logo" 
                            style={{width:'10vw', borderRadius:'5vw',}} 
                        />
                        <span className="black-text logo-font-family" style={{fontSize:'5vw'}}>
                            rypto Cash Fast, Inc.
                        </span>
                    </Grid>
                    <Grid style={{textAlign:'center', marginTop:'10vw'}}>
                        <h2 style={{fontWeight:100}}>Business Integration Options</h2>
                    </Grid>
                    <Grid style={{justifyContent:'center', display:'flex'}}>
                        <img 
                            src="https://www.cryptocashfast.com/logo512.png" 
                            alt="Logo" 
                            style={{width:119, borderRadius:80}} 
                        />
                    </Grid>
                    <Grid style={{textAlign:'center'}}>
                        <h1>Accept crypto. Get paid in USD.</h1>
                        <p style={{maxWidth:'550px', margin:'auto', fontSize:25, backgroundColor:'white'}}>
                            Crypto Cash Fast enables you to accept crypto from your customers but get paid in US Dollars. Without changing your current payment gateway or adding any additional code, Crypto Cash Fast enables your customers to buy with crypto while you get paid in US Dollars.
                        </p>
                    </Grid>
                    <Grid style={{maxWidth:'max-content', textAlign:'center', margin:'auto'}}>
                        <h1 style={{display:'flex', alignItems:'center'}}><Memory sx={{fontSize:50}} /> 3 available integration options</h1>
                            <Typography variant={'h5'} color="primary">Application programming interface (API)</Typography>
                            <Typography variant={'h5'} color="primary">Ecommerce</Typography>
                            <Typography variant={'h5'} color="primary">Point of sale (POS) System</Typography>
                    </Grid>
                    <hr />
                    <Grid style={{maxWidth:'600px', margin:'auto'}}>

                        <Grid style={{alignItems:'center'}}>
                            <h1 style={header}>
                                <Hub sx={{fontSize:50}} /> API Integration
                            </h1>
                            <h3>
                                Our standalone API allows you to create custom checkouts and workflows.
                            </h3>
                        </Grid>
                        <Grid>
                            <ul style={{fontSize:20}}>
                                <li>Create a checkout</li>
                                <li style={{marginLeft:20}}>Checkout item you can use later</li>
                                <li>Create a charge</li>
                                <li style={{marginLeft:20}}>Simple charge</li>
                                <li style={{marginLeft:20}}>Use checkout item</li>
                                <li>Create an Invoice</li>
                                <li style={{marginLeft:20}}>Label payment</li>
                                <li style={{marginLeft:20}}>Add message</li>
                                <li style={{marginLeft:20}}>Use a checkout item</li>
                            </ul>
                        </Grid>
                    </Grid>
                    <hr />
                    <Grid style={{maxWidth:'800px', margin:'auto'}}>

                        <Grid style={{alignItems:'center'}}>
                            <h1 style={header}>
                                <ShoppingCart sx={{fontSize:50}} /> Ecommerce
                            </h1>
                            <h3>
                                Host your store catalogue on our platform. No additional work.
                            </h3>
                        </Grid>
                        <Grid>
                            <ul style={{fontSize:20}}>
                                <li>Use your own domain</li>
                                <li style={{marginLeft:20}}>Crypto Cash Fast, Inc. provides you with the subdomain of ‘yourcompany’.cryptocashfast.com. With ‘yourcompany’ being the name of your company</li>
                                <li style={{marginLeft:20}}>You can customize your store’s catalogue domain.</li>
                                <li >We do all the development work</li>
                                <li style={{marginLeft:20}}>Send us your items and necessary details and we will create your CryptoCashFast.com’s hosted store. </li>
                            </ul>
                        </Grid>
                    </Grid>
                    <hr />
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <h1 style={header}>
                                <PointOfSale sx={{fontSize:50}} /> Point of sale (POS) system
                            </h1>
                            <h3>
                                Handle customer payments in person.
                            </h3>
                        </Grid>
                        <Grid item xs={6}>
                            <ul style={{fontSize:20}}>
                                <li>Simple point of sale system</li>
                                <li style={{marginLeft:20}}>Simply enter the sale’s cost to process a transaction</li>
                                <li style={{marginLeft:20}}>Shows on your payments and balances as “Point of sale”</li>
                                <li style={{marginLeft:20}}>Simple payment processing</li>
                            </ul>
                        </Grid>
                        <Grid item xs={6}>
                            <ul style={{fontSize:20}}>
                                <li>Collect your payment, no other additional information needed</li>
                                <li>Charge customers in person</li>
                                <li style={{marginLeft:20}}>Use our point of sale on a tablet, cell phone or computer.</li>
                            </ul>
                        </Grid>
                    </Grid>
                    <Grid style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:100 }}>
                            <img 
                                src="https://www.cryptocashfast.com/logo512.png" 
                                alt="Logo" 
                                style={{width:'10vw', borderRadius:'5vw',}} 
                            />
                        
                            <span className="black-text logo-font-family" style={{fontSize:'5vw'}}>
                                rypto Cash Fast, Inc.
                            </span>
                        </Grid>
                    <Grid style={{textAlign:'center'}}>
                        <h2 style={{fontWeight:100}}>Business Integration Options</h2>
                    </Grid>
                </Grid>
            </Container>
       
        </>
    )
}