import { AddCircle, RemoveCircle } from '@mui/icons-material'
import { Box, Button, Container, TextField, Typography } from '@mui/material/'
import { useState, useEffect } from 'react'

export const MetaController = ({ remotekey, setNewKey }:any) => {

    const [key, setKey] = useState<any>([])
    const [keyField, setKeyField] = useState<any>('')
    const [valueField, setValueField] = useState<any>('')

    const handleAddKey = () => {
        setKey([...key, {[keyField]: valueField}])
        setNewKey([...key, {[keyField]: valueField}])
    }

    const handleRemoveKey = (value:any) => {
        let newKey
        let keysX = Object.keys(value)[0]
        newKey = key?.filter((keys:any) => Object.keys(keys)[0] !== keysX)
        setKey(newKey)
        setNewKey(newKey)
    }

    useEffect(() => {
      setKey(remotekey)
    }, [remotekey])
    
    
    return (
        <Box style={{backgroundColor:'aliceblue', padding:10}}>
            <Container>
                {key?.map((item:any, index:any) => 
                    <Typography key={index} variant='h5' style={{marginBottom:5}}>
                        {JSON.stringify(item)}
                        <RemoveCircle onClick={() => handleRemoveKey(item)} />
                    </Typography>
                )}
            </Container>
            <Container>
                <TextField id="outlined-basic" onChange={(e) => setKeyField(e.target.value)} label="Key" variant="outlined" />
                <TextField id="outlined-basic" onChange={(e) => setValueField(e.target.value)} label="Value" variant="outlined" />
            </Container>
            <Button onClick={handleAddKey} startIcon={<AddCircle  />}>Add Metadata</Button>

        </Box>
    )
}