import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import SlideInDialog from 'components/SlideIn.Dialog';
import { UserContext } from 'contexts/auth.context';

export const GettingStarted = () => {
    const user = React.useContext(UserContext)
    const [open, setOpen] = React.useState(user?.getting_started ?? false);
    let navigate = useNavigate()

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <SlideInDialog 
            type={'getting_started'}
            open={open}
            setOpen={setOpen}
            title={"Getting Started"} 
            content={" Let's start using the Cypto Cash Fast ( CFF ) platform. You can use CCF to buy and sell Bitcoin."} 
            buttonTitle1={"Buy BTC"} 
            buttonFunciton1={() => {
                navigate('/buy-bitcoin')
                handleClose()
                }} 
            buttonTitle2={"Convert BTC to USD"} 
            buttonFunciton2={() => {
                navigate('/wallets')
                handleClose()
                }} 
      />
    )
}