import { Box, Card, CardContent, Container, Typography } from "@mui/material";
import { useList } from "@refinedev/core";
import { UserContext } from "contexts/auth.context";
import { useContext, useEffect, useState } from "react";

export const RecentTransactionsWidget = (props:any) => {
    
    const [wallet_list, setWalletList] = useState([])

    const user = useContext(UserContext)

    const { data, isLoading, isError } = useList({
        resource: "crypto_transactions",
        meta: {
            headers: { wallet: JSON.stringify(wallet_list), 'special-access-token':process.env.REACT_APP_X_Access },
        }
    });

    const transactions = data?.data ?? []

    const { data: walletData, isLoading: walletIsLoading } = useList({
        resource: "wallets",
        meta: {
                headers: { page_number:0, business:user?.id, limitlisted:0, 'special-access-token':process.env.REACT_APP_X_Access }
            },
    });

    useEffect(() => {
        let local_walletList:any = []
        walletData?.data.filter(item => item.address && local_walletList.push(item.address) )
        setWalletList(local_walletList)
    }, [walletData])
    

    return (
    <>
    <Box 
        sx={{
            maxWidth: 300,
            marginBottom:5
        }}
    >
        <Card sx={{ minWidth: 200 }}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Recent Transactions
                </Typography>
                {transactions?.length === 0 && <Typography>No Transactions Listed</Typography>}
                {transactions?.slice(0,1).map((item, index) =>
                    <Container style={{marginBottom:10}} key={index}>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary" style={{wordWrap:'break-word'}}>Wallets: {item.wallet.splice(0,2).map((item:any, index:any) => <span key={index}>{item}, </span>)}{item.wallet.length > 2 && <span>...</span>}</Typography>
                        <Typography variant="body2">Payment Status: {item.payment_status}</Typography>
                        <Typography variant="body2">Payout Status: {item.payout_status}</Typography>
                    </Container>
                )}
            </CardContent>
        </Card>
    </Box>
    <Box 
        sx={{
            maxWidth: 300,
            marginBottom:5
        }}
    >
        <Card sx={{ minWidth: 200 }}>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Recent Wallets
                </Typography>
                {walletData?.data.length === 0 && <Typography>No Wallets Listed</Typography>}
                {walletData?.data.slice(0,1).map((item, index) =>
                    <Container style={{marginBottom:10}} key={index}>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary" style={{wordWrap:'break-word'}}>{JSON.stringify(item.meta)}</Typography>
                        <Typography variant="body2" style={{wordWrap:'break-word'}}>Address: {item.address}</Typography>
                        <Typography variant="body2" style={{wordWrap:'break-word'}}>Wallet: {item.wallet}</Typography>
                    </Container>
                )}
            </CardContent>
        </Card>
    </Box>
    </>
    )
}