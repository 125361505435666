import { useOne } from "@refinedev/core";
import { useParams } from "react-router-dom";
import { TextField, Box, Typography } from '@mui/material';
import { useEffect } from "react";

export const AdminPayoutsShow = () => {
    const  { id } = useParams()

    let tempID = id ?? ''

    const { data, isLoading, isError } = useOne({
        dataProviderName: "admin",
        resource: "payouts",
        id:tempID,
        queryOptions: {
            staleTime: Infinity
        },
        meta: {
            headers: { 'special-access-token':process.env.REACT_APP_X_Access }
        },
    });

    const payout = data?.data ?? {};

    let fields_key = [
        {label:"Amount", value:payout?.currency === "USD" ? "$"+Number(payout?.amount).toFixed(2).toLocaleString() : payout?.amount},
        {label:"Business", value:payout?.business},
        {label:"CreatedAt", value:new Date(payout?.createdAt).toLocaleString()},
        {label:"Currency", value:payout?.currency},
        {label:"Id", value:payout?.id},
        {label:"Status", value:payout?.status},
        {label:"UpdatedAt", value:new Date(payout?.updatedAt).toLocaleString()},
        {label:payout?.currency === "USD" ? "Recipient ID" : "Bitcoin Wallet", value:payout?.recipientId},
    ]

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Something went wrong!</div>;
    }

    return (
        <Box
            sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
            }}
            style={{maxWidth:600, margin:'auto', maxHeight:'calc(100vh - 200px)', overflow:'auto'}}
        >
            <Typography variant="h5">Payout Details</Typography>
            {fields_key.map((item:any, index:any) => 
                <TextField key={index} id="outlined-basic" label={item.label} variant="outlined" value={item.value} />
            )}
            <Typography variant="h5">Transactions</Typography>
            {payout?.transactions.map((item:any, index:any) =>
               <TextField multiline key={index} id="outlined-basic" label={"Transaction #"+index+1} variant="outlined" value={JSON.stringify(item).slice(9).slice(0, -2)} />
            )}
        </Box>
    );
}