import { AuthBindings } from "@refinedev/core";
import axios from "axios";

export const TOKEN_KEY = "refine-auth";

export const authProvider: AuthBindings = {
  login: async ({ username, email, password }) => {
    if ((username || email) && password) {
      const results = await axios.post(process.env.REACT_APP_API+"/auth/signin_bussiness", {email, password}, {
        headers: {
          'special-access-token': '123456789yu'
        }
      })
      localStorage.setItem('user', JSON.stringify(results.data))
      localStorage.setItem(TOKEN_KEY, username);
      return {
        success: true,
        redirectTo: "/dashboard",
      };
    }

    return {
      success: false,
      error: {
        name: "LoginError",
        message: "Invalid username or password",
      },
    };
  },
  logout: async () => {
    let confirm = window.confirm
    if (confirm("Are you sure you want to log out?")) {
      localStorage.removeItem(TOKEN_KEY);
      localStorage.removeItem('user')
      return {
        success: true,
        redirectTo: "/login",
      };
    } 

    return {
      success: false,
      error: {
        name: "LogoutError",
        message: "You chose not to log out",
      },
    };
  },
  register: async (props) => {
    const reg:any = await axios.post(process.env.REACT_APP_API+'/auth/signup_business', props, {
      headers: {
        'special-access-token': '123456789yu'
      }
    })
    .then(registration => {
      return {
        success: true,
        redirectTo: "/login",
      };
    })
    .catch(err => {
      return {
        success: false,
        error: {
          name: "RegisterError",
          message: err?.request?.responseText ? err?.request?.responseText : "There has been an error"
        },
      };

    })
    
    if(reg?.success === true){
      return {
        success: true,
        redirectTo: "/login",
      };
    }

    return {
      success: false,
      error: {
        name: "RegisterError",
        message: reg?.error ? reg?.error?.message : "There has been an error",
      },
    };

  },
  check: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return {
        authenticated: true,
      };
    }

    return {
      authenticated: false,
      redirectTo: "/login",
    };
  },
  getPermissions: async () => null,
  getIdentity: async () => {
    const user:any = localStorage.getItem('user');
    if (user) {
      let localUser:any = JSON.parse(user)
      return localUser;
    }
    return null;
  },
  onError: async (error) => {
    console.error(error);
    return { error };
  },
  forgotPassword: async ({ email }) => {
    // You can handle the reset password process according to your needs.
    try {
      const res = await axios.post("https://api.cryptocashfast.com/password/reset", {email})
      if (res.data.result === "success") {
        alert(res?.data?.message)
        // If process is successful.
        return {
            success: true,
        };
      } else if (res.data.result === "error") {

        return {
            success: false,
            error: {
                name: "Forgot Password Error",
                message: "Invalid email or password",
            },
        };
      }
    } catch (err) {

      return {
          success: false,
          error: {
              name: "Forgot Password Error",
              message: "There has been an error",
          },
      };
      
    }

    return {
      success: false,
      error: {
          name: "Forgot Password Error",
          message: "Invalid email or password",
      },
    };
  },
};
