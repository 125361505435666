import { Button, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Paper, Checkbox, Container } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { CheckCircle } from "@mui/icons-material";
import { getTomorrow } from 'components/utils/utils';
import { useCreate, useMany } from "@refinedev/core";
import { useState } from 'react';
import axios from 'axios';

export const PayoutsTable = (props:any) => {
    const { type, payoutItems, payouts, handleCheckbox, canPay } = props

    let navigate = useNavigate()
    
    const { mutate } = useCreate();

    const handleSendACH = ({ amount, crypto_transactions_ids, payout_id, recipientId }:any) => {
        mutate({
          dataProviderName: "admin",
          resource: "ach-pt",
          values: {
              recipientId,
              amount, 
              crypto_transactions_ids, 
              payout_id
          },
          meta: {
              headers: { 'special-access-token':process.env.REACT_APP_X_Access }
          },
        },
        {
            onError: (error, variables, context) => {
                // An error occurred!
                console.log(error)
            },
            onSuccess: (data, variables, context) => {
                // Let's celebrate!
                console.log(data)
            },
        },);
      }
  
      const handleSendCrypto = ({ btcReciever, btc_amount, payout_id, usd_amount }:any) => {
        mutate({
          dataProviderName: "admin",
          resource: "crypto-pt",
          values: {
            btcReciever, 
            btc_amount, 
            usd_amount, 
            payout_id
          },
          meta: {
              headers: { 'special-access-token':process.env.REACT_APP_X_Access }
          },
        },
        {
            onError: (error, variables, context) => {
                // An error occurred!
                console.log(error)
            },
            onSuccess: (data, variables, context) => {
                // Let's celebrate!
                console.log(data)
            },
        },);
      }

        
      const handleSendACHRefund= async ({ amount, payout_id, user }:any) => {
        const ach = await axios.get("https://api.cryptocashfast.com/api/ach/user/"+user)
        let ccfACH = ach.data.filter((item:any) => item.type === 'ccf')
        let recipientId = ccfACH[0].recipientId
        
        mutate({
          dataProviderName: "admin",
          resource: "ach-rd",
          values: {
            recipientId, 
            amount, 
            payout_id
          },
          meta: {
              headers: { 'special-access-token':process.env.REACT_APP_X_Access }
          },
        },
        {
            onError: (error, variables, context) => {
                // An error occurred!
                alert(JSON.stringify(error.response.data.errors))
            },
            onSuccess: (data, variables, context) => {
                // Let's celebrate!
                console.log(data)
            },
        },);
      }

    return (
        <TableContainer component={Paper}>
              
            <Container style={{justifyContent:'flex-end', display:'flex', marginTop:10}}>
                {payoutItems.length > 0 && <Button variant="contained">Send Bulk Payout</Button>}
            </Container>

            <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                <TableCell align="right"></TableCell>
                <TableCell align="center">Amount</TableCell>
                <TableCell align="center">Company Name</TableCell>
                <TableCell align="center">Created Time</TableCell>
                {type === "BTC" &&  <TableCell align="center">Cancel Time</TableCell>}
                <TableCell align="center">Currency</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {payouts?.map((row:any) => (
                row.currency === type &&
                <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell align="right">
                    <Checkbox 
                        //checked={checked}
                        onChange={() => handleCheckbox(row)} />
                    </TableCell>
                    <TableCell component="th" scope="row">
                    {type === "USD" && "$"+Number(row.amount).toFixed(2).toLocaleString()}
                    {type === "BTC" && Number(row.amount).toFixed(6)}
                    
                    </TableCell>
                    <TableCell align="right">{row.business}</TableCell>
                    <TableCell align="right">{new Date(row.createdAt).toLocaleString()}</TableCell>
                    {type === "BTC" && <TableCell align="right">{getTomorrow(row.createdAt)}</TableCell>}
                    <TableCell align="right">{row.currency}</TableCell>
                    <TableCell align="right">{row.status}</TableCell>
                    <TableCell align="right"><Button onClick={() => navigate('/manage-payouts/show/'+row.id)}>View</Button></TableCell>
                    <TableCell align="right">
                        {type === "USD" && 
                            <Button 
                                disabled={!canPay.includes(row.id)} 
                                onClick={() => handleSendACH({amount:row.amount, crypto_transactions_ids:row.transactions, payout_id:row.id, recipientId:row.recipientId})}
                            >
                                Complete ACH  Payout {canPay.includes(row.id) && <CheckCircle />}
                            </Button>
                        }
                        {type === "BTC" && row.status !== "Cancelled" &&
                            <Button 
                                disabled={!canPay.includes(row.id)} 
                                onClick={() => handleSendCrypto({btcReciever:row.recipientId, btc_amount:row.currency_amount, payout_id:row.id, usd_amount:row.usd_amount})}
                            >
                                Complete Crypto Payout {canPay.includes(row.id) && <CheckCircle />}
                            </Button>
                        }
                        {type === "BTC" && row.status === "Cancelled" &&
                            <Button 
                                //disabled={!canPay.includes(row.id)} 
                                onClick={() => handleSendACHRefund({amount:row.amount, payout_id:row.id, user:row.business})}
                            >
                                Refund
                            </Button>
                        }
                    </TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
    )
}