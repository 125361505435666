import { Box, Button, LinearProgress, InputLabel, MenuItem, Select, TextField, Typography, Modal, Alert } from "@mui/material"
import { useUpdate, useMany } from "@refinedev/core";
import axios from "axios";
import { style } from "components/utils/styles";
import { UserContext } from "contexts/auth.context";
import { useContext, useEffect, useState } from "react"

export const CreateACHRecipient = ( props:any ) => {

    const { open, handleClose } = props

    const [electronicAccountType, setElectronicAccountType] = useState('businessSavings')
    const [achID, setACHId] = useState<any>([])
    const [loading, setLoading] = useState(false)
    const [showForm, setShowForm] = useState(true)

    const user = useContext(UserContext)

    const { data:aches } = useMany({
        resource: "ach/user/"+user?.id,
        ids:[],
        meta: {
            headers: { 'special-access-token':process.env.REACT_APP_X_Access }
        },
    });

    const ach = aches?.data ?? []

    const { mutate } = useUpdate();

    const createACH = (e: React.FormEvent<HTMLFormElement>) => {
        setLoading(true)
        const values = {
            electronicAccountType: e.currentTarget.electronicAccountType.value,
            address: {
            address1: e.currentTarget.address1.value,
            city: e.currentTarget.city.value,
            region: e.currentTarget.region.value,
            postalCode: e.currentTarget.postalCode.value,
            country:"USA"
            },
            name: e.currentTarget.bank_name.value,
            emails: [user?.email],
            accountNumber: e.currentTarget.accountNumber.value,
            routingNumber: e.currentTarget.routingNumber.value,
            auth_user:user?.id
        };

        axios.post(process.env.REACT_APP_API+'/create/ach/receipient', values)
        .then(results => {
            
            if(Object.keys(results.data).length){
                setLoading(false)
                alert("You new ACH options have been saved successfully!")
                window.location.reload()
            }
        })

    }

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if(ach.length  > 1){

            mutate(
                {
                    resource: "ach",
                    values: {
                        user: "_"+user?.id+"_",
                    },
                    id:achID[0],
                    meta: {
                        headers: { 'special-access-token':process.env.REACT_APP_X_Access }
                    },
                },
                {
                    onError: (error, variables, context) => {
                        // An error occurred!
                    },
                    onSuccess: (data, variables, context) => {
                        // Let's celebrate!
                    },
                },
            );

            mutate(
                {
                    resource: "ach",
                    values: {
                        user: "_"+user?.id+"_",
                    },
                    id:achID[1],
                    meta: {
                        headers: { 'special-access-token':process.env.REACT_APP_X_Access }
                    },
                },
                {
                    onError: (error, variables, context) => {
                        // An error occurred!
                    },
                    onSuccess: (data, variables, context) => {
                        // Let's celebrate!
                    },
                },
            );
        }

        createACH(e)
    }

    useEffect(() => {
        let localList:any = []
        ach.filter(item => localList.push(item.id))
        setACHId(localList)
    }, [ach])
    

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
    
        <form onSubmit={onSubmit}>
            {loading ?
                <>
                    <Typography>Please do not refresh this page.</Typography>
                    <LinearProgress />
                </>
                :
                <>
                    {ach.length  < 1 ?
                        <Alert severity="error">No ACH on file</Alert>
                    :
                        <Typography>Currenct Payout: {ach[0].name}</Typography>
                    }
                    {showForm && 
                    <>
                    <Box sx={{paddingLeft:5, paddingRight:5, marginTop:3}}>
                        <InputLabel>Account Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name='electronicAccountType'
                            value={electronicAccountType}
                            label="Account Type"
                            style={{margin:5}}
                            onChange={(e) => setElectronicAccountType(e.target.value)}
                            fullWidth
                            required
                        >
                            <MenuItem value={'businessChecking'}>Business Checking</MenuItem>
                            <MenuItem value={'businessSavings'}>Business Savings</MenuItem>
                            <MenuItem value={'personalChecking'}>Personal Checking</MenuItem>
                            <MenuItem value={'personalSavings'}>Personal Savings</MenuItem>
                        </Select>
                        {[
                            {place:"Address", name:"address1", value:'4501 23rd Avenue S'}, 
                            {place:"City", name:"city", value:'Fargo'},
                            {place:"State", name:"region", value:'ND'},
                            {place:"Zip Code", name:"postalCode", value:'58104'},
                            {place:"Account Name", name:"bank_name", value:'Alex ACH Test'},
                            {place:"Account Number", name:"accountNumber", value:"3631271038"},
                            {place:"Routing Number", name:"routingNumber", value:"011401533"},
                        ].map((item, index) =>
                            <TextField fullWidth style={{margin:5}} key={index} placeholder={item.place} name={item.name} label={item.place} required />
                        )}
                    </Box>
                    <Button disabled={loading} fullWidth type="submit" variant="contained" color="primary">Create New ACH account</Button>
                    </>
                    }
                </>
            }
        </form>
        
        </Box>
        </Modal>
    )
}