import React, { useState, useEffect, useContext } from "react";
import {
    useDataGrid,
    EditButton,
    ShowButton,
    List,
    DateField,
    CreateButton,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps, useMany } from "@refinedev/core";
import { Container, Box, Button, Typography, Backdrop } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { reponsiveWidth } from "components/utils/styles";
import SlideInDialog from "components/SlideIn.Dialog";
import { useNavigate } from "react-router-dom";
import { UserContext } from "contexts/auth.context";

export const WalletList: React.FC<IResourceComponentsProps> = () => {
    const [page_number, setPageNumber] = useState(0)
    const user = useContext(UserContext)
    const { dataGridProps } = useDataGrid({
        meta: {
            headers: { page_number, business:user?.id, 'special-access-token':process.env.REACT_APP_X_Access }
        },
    });

    const { data: walletData, isLoading: walletIsLoading } = useMany({
        resource: "wallets",
        ids: dataGridProps?.rows?.map((item: any) => item?.wallet) ?? [],
        queryOptions: {
            enabled: !!dataGridProps?.rows,
        },
        meta: {
            headers: { 'special-access-token':process.env.REACT_APP_X_Access }
        },
    });

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "id",
                headerName: "Id",
                minWidth: 50,
            },
            {
                field: "address",
                flex: 1,
                headerName: "Address",
                minWidth: 200,
                renderCell: function render({ value }) {
                    return value ?? "Creating wallet address, this can take up to 3 minutes.";
                },
            },
            {
                field: "wallet",
                flex: 1,
                headerName: "Wallet",
                minWidth: 300,
                renderCell: function render({ value }) {
                    return value ?? "Creating wallet address, this can take up to 3 minutes.";
                },
            },
            {
                field: "createdAt",
                flex: 1,
                headerName: "Created",
                minWidth: 300,
                renderCell: function render({ value }) {
                    return <DateField value={new Date(value)} />;
                },
            },
            {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <EditButton hideText recordItemId={row.id} />
                            <ShowButton hideText recordItemId={row.id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],
        [walletData?.data],
    );

  const [gettingStarted, setGetStart] = useState(false)
  const [howToCreateWallet, setHowToCreateWallet] = useState(false)
  let navigate = useNavigate()

  useEffect(() => {
    if(user.getting_started){
        setTimeout(() => {
            setGetStart(true)
        }, 1000);
    }
  }, [])
  
    return (
        <>
        <List
            headerButtons={({ defaultButtons }) => (
                <>
                    {howToCreateWallet ? 
                    <Container style={{zIndex:10000, padding:5, backgroundColor:'yellow', borderRadius:5}}>
                        <CreateButton>Create Wallet</CreateButton>
                        <Box style={{maxWidth:200, marginTop:10}}>
                            <Typography>
                                This is the button to press if you would like to create a deposit wallet.
                            </Typography>
                        </Box>
                    </Container>
                    :
                    <CreateButton>Create Wallet</CreateButton>
                    }
                </>
            )}
        >
            <DataGrid {...dataGridProps} columns={columns} hideFooterPagination={true} autoHeight style={{maxWidth:reponsiveWidth, overflow:'auto'}} />

            
            <Box style={{display:'flex', justifyContent:'flex-end'}}>
                {page_number !== 0 && <Button onClick={() => setPageNumber(page_number - 1)} startIcon={<ArrowBack />}></Button>}
                {dataGridProps?.rows.length > 9 && <Button onClick={() => setPageNumber(page_number + 1)} startIcon={<ArrowForward />}></Button>}
            </Box>
            
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={howToCreateWallet}
                onClick={() => setHowToCreateWallet(false)}
            >
            </Backdrop>
        </List>
        
        <SlideInDialog 
            type={'getting_started'}
            open={gettingStarted} 
            setOpen={setGetStart} 
            title={"Convert BTC to USD"} 
            content={
                <>
                    <Typography variant="h6">
                        First, Create a Wallet
                    </Typography>
                    <Typography>
                        Any coins sent to any wallets you create under your account will be converted to USD and sent to your ACH payout account. First create a wallet.
                    </Typography>
                    <Button onClick={() => {
                        setHowToCreateWallet(true)
                        setGetStart(false)
                    }} style={{paddingLeft:0}}>
                        Step One : Create a Wallet
                    </Button>
                </>
            }
            buttonTitle1={"Next Lesson : Learn how to Buy Bitcoin"} 
            buttonFunciton1={() => {
                setGetStart(false)
                navigate('/buy-bitcoin')
            }}
        />
        </>
    );
};
