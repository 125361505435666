import { useList } from "@refinedev/core";
import { useEffect, useState } from 'react'
import { Grid, Card, CardContent, Typography, Box, Tabs, Tab, Container, FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { reponsiveWidth } from "components/utils/styles";
import { CryptoBalances } from "components/admin/crypto.balance";
import { PayoutsTable } from "components/admin/payouts.table";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props:any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export const AdminPayouts = () => {
    const [payoutItems, setPayoutItems] = useState<any>([])
    const [payoutFilter, setPayoutFilter] = useState('Pending')

    const { data, isLoading, isError } = useList({
        dataProviderName: "admin",
        resource: "payouts",
        filters: [
          {
              field: "status",
              operator: "eq",
              value: payoutFilter,
          },
        ],
        queryOptions: {
            staleTime: Infinity
        },
        meta: {
            headers: { 'special-access-token':process.env.REACT_APP_X_Access }
        },
    });
    
    const { data:ccf_bank_data } = useList({
      dataProviderName: "admin",
      resource: "bank/ccf/balance",
      queryOptions: {
          staleTime: Infinity
      },
      meta: {
          headers: { 'special-access-token':process.env.REACT_APP_X_Access }
      },
    });

    const { data:crypto_data } = useList({
        dataProviderName: "admin",
        resource: "crypto/balance",
        queryOptions: {
            staleTime: Infinity
        },
        meta: {
            headers: { 'special-access-token':process.env.REACT_APP_X_Access }
        },
    });

    const crypto_bank = crypto_data?.data
    
    const payouts = data?.data ?? [];

    const ccf_bank = ccf_bank_data?.data;
    
    const handleCheckbox = (row:any) => {
      let replicate:any = []
      payoutItems.filter((item:any) => item.id === row?.id && replicate.push(item))
      if(replicate.length > 0){
        let removedReplica = payoutItems.filter((item:any) => item.id !== row?.id)
        setPayoutItems(removedReplica)
      } else {
        setPayoutItems([...payoutItems, row])
      }
    }

    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    const [usdPayoutsPay, setUSDPayoutPay] = useState<any>([])

    useEffect(() => {
      let newBank:any = []
      ccf_bank?.filter(item => item.id === '1e37e9c4-03e5-11ee-87fa-1bfd9c3ecbc4' && newBank.push(item))
      
      let bankBalance = newBank[0] ? newBank[0].availableBalance : 0
      
      let idsToPay:any = []
      let ids:any = []

      payouts.filter(payout => {
        let amount = payout.amount
        
        if(amount < bankBalance && amount + idsToPay.reduce((partialSum:any, a:any) => partialSum + a, 0) < bankBalance && payout.currency === "USD"){
          idsToPay.push(Number(payout.amount))
          ids.push(payout.id)
        }

      })

      setUSDPayoutPay(ids)
    }, [ccf_bank])

    const [btcPayoutsPay, setBTCPayoutPay] = useState<any>([])
    
    const satoshiToBitcoin = (satoshi:number) => {
      return satoshi / 100000000;
    }

    useEffect(() => {
      let newBank:any = []
      crypto_bank?.filter(item => item.address === process.env.REACT_APP_CUSTOMER_WALLET && newBank.push(item))
      
      let bankBalance = newBank[0] ? satoshiToBitcoin(newBank[0].final_balance) : 0
      
      let idsToPay:any = []
      let ids:any = []

      payouts.filter(payout => {
        let amount = Number(payout.amount)
        
        if(amount < bankBalance && amount + idsToPay.reduce((partialSum:any, a:any) => partialSum + a, 0) < bankBalance && payout.currency === "BTC"){
          idsToPay.push(Number(payout.amount))
          ids.push(payout.id)
        }

      })

      setBTCPayoutPay(ids)
    }, [crypto_bank])
    
    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Something went wrong!</div>;
    }

    return (
      <Container style={{maxWidth:reponsiveWidth}}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Transactions filter</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={payoutFilter}
            label="Transactions filter"
            onChange={(e) => setPayoutFilter(e.target.value)}
          >
            <MenuItem value={'Pending'}>Pending</MenuItem>
            <MenuItem value={"Completed"}>Completed</MenuItem>
            <MenuItem value={"Paid"}>Paid</MenuItem>
            <MenuItem value={"Cancelled"}>Cancelled</MenuItem>
            <MenuItem value={"Refunded"}>Refunded</MenuItem>
          </Select>
        </FormControl>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="USD" {...a11yProps(0)} />
            <Tab label="Crypto" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <Grid container spacing={2}>   
            {ccf_bank?.map((item, index) =>
            item.accountNumber === "202389136068" &&
            <Grid key={index} item>
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {item.name}
                        </Typography>
                        <Typography variant="h5" component="div">
                        Balance: {"$"+Number(item.availableBalance).toFixed(2).toLocaleString()}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        {item.legalBusinessName}
                        </Typography>
                        <Typography variant="body2">
                        Account Number: {item.accountNumber}
                        <br />
                        Type: {item.kind}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            )}
          </Grid>

          <PayoutsTable type={'USD'} payoutItems={payoutItems} payouts={payouts} handleCheckbox={handleCheckbox} canPay={usdPayoutsPay} />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <Grid container spacing={2}>
            <CryptoBalances slice={1} />
          </Grid>

          <PayoutsTable type={'BTC'} payoutItems={payoutItems} payouts={payouts} handleCheckbox={handleCheckbox} canPay={btcPayoutsPay} />
        </CustomTabPanel>
      </Container>
    );
}