import { Box, Button, Card, CardActions, CardContent, Typography } from "@mui/material";
import { UserContext } from "contexts/auth.context";
import { useUpdate } from "@refinedev/core";
import { useContext } from 'react'

export const GettingStartedWidget = () => {
    const user = useContext(UserContext)
  
    const { mutate } = useUpdate();
  
    const handleStartTutorial = () => {
      mutate({
        resource: "users",
        values: {
            getting_started: true
        },
        id: user?.id,
        meta: {
            headers: { 'special-access-token':process.env.REACT_APP_X_Access }
        },
      });

      setTimeout(() => {
        window.location.reload()
      }, 2000);
    }
    
    return (
    <>
        {!user?.getting_started &&
            <Box 
                sx={{
                    maxWidth: 300,
                    marginBottom:5
                }}
            >
                <Card sx={{ minWidth: 250 }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        Gettting Started Tutorial
                        </Typography>
                        <Typography variant="h5" component="div">
                        Step by step guide
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        How to buy and sell Bitcoin.
                        </Typography>
                        <Typography variant="body2">
                        Create BTC payment gateways
                        <br />
                        {'Easy setup.'}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button onClick={handleStartTutorial} size="small">Launch Tutorial</Button>
                    </CardActions>
                </Card>
            </Box>
        }
    </>
    )
}