import { Authenticated, ErrorComponent, Refine } from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import { AuthPage, notificationProvider, RefineSnackbarProvider, ThemedLayoutV2 } from "@refinedev/mui";

import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import dataProvider from "@refinedev/simple-rest";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import { authProvider } from "./authProvider";
import { ColorModeContextProvider } from "./contexts/color-mode";
import { MuiInferencer } from "@refinedev/inferencer/mui";
import { PayoutList } from "pages/payouts/List";
import { PayoutShow } from "pages/payouts/Show";
import { TransactionList } from "pages/transactions/List";
import { TransactionShow } from "pages/transactions/Show";
import { Typography, Box } from "@mui/material";
import { AccountBalance, Balance, Block, CurrencyBitcoin, Dashboard, GroupAdd, Groups3, Paid, Receipt, ReceiptLong } from '@mui/icons-material';
import DashboardHome from "pages/dashboard";
import WelcomePage from "pages/welcome/WelcomePage";
import { CustomHeader } from "components/CustomHeader";
import { UserList } from "pages/accounts/List";
import { UserShow } from "pages/accounts/Show";
import { UserEdit } from "pages/accounts/Edit";
import { useContext, useEffect } from "react";
import { RegisterPage } from "pages/register";
import { Logo } from "components/logo";
import { WalletList } from "pages/wallet/List";
import { WalletCreate } from "pages/wallet/wallet.create";
import { UserContext } from "contexts/auth.context";
import { WalletShow } from "pages/wallet/Show";
import { WalletEdit } from "pages/wallet/Edit";
import { CryptoTransactionCreate } from "pages/transactions/Create.Transactions";
import { AdminPayouts } from "pages/admin/admin_payouts.list";
import { AdminPayoutsShow } from "pages/admin/admin_payouts.show";
import { CCFBalances } from "pages/admin/cff_balances.list";
import { AllTransactionList } from "pages/transactions/AllList";
import { FailedTransactionList } from "pages/failed_transactions/List";
import { FailedTransactionShow } from "pages/failed_transactions/Show";
import { KYCVerify } from "components/accounts/Kyc";
import { PasswordUpdate } from "pages/password_reset";

const customTitleHandler = ({ resource, action, params }:any) => {
  let title = "CryptoCashFast | CCF"; // Default title

  if(resource && action) {
      title = `CCF ${resource.name} ${action} ${params.id ? params.id : ''}`;
  }

  return title;
};


const CustomTitle = ({ collapsed }:any) => (
    <div>{collapsed ? <img src="https://www.cryptocashfast.com/logo512.png" width={"100%"} /> : <Typography variant="h6" color="primary" fontFamily={"Helvetica"}>CryptoCashFast</Typography>}</div>
);

function App() {

  const userDetails = useContext(UserContext);

  let ROLE_ADMIN = '614b954d3e09732573ccd312'

  useEffect(() => {
   console.log('%cDo NOT make any changes here! Beware of scams asking you to enter any information into this window.', 'color:red; background-color:yellow; font-size:40px; font-weight:900');
   console.log = console.warn = console.error = () => {};
  }, [])
  
  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <CssBaseline />
          <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
          <RefineSnackbarProvider>
            <Refine
              Title={CustomTitle}
              dataProvider={{
                default: dataProvider(process.env.REACT_APP_API ?? ""),
                admin: dataProvider(process.env.REACT_APP_API+"/admin"),
                }}
              notificationProvider={notificationProvider}
              routerProvider={routerBindings}
              authProvider={authProvider}
              resources={[
                {
                    name: "dashboard",
                    list: "/dashboard",
                    meta:{label:'Dashboard'},
                    icon: <Dashboard />
                },
                {
                    name: "manage-payouts",
                    list: "/manage-payouts",
                    show: "/manage-payouts/:id",
                    icon: <Paid />,
                    meta: {
                        label:'Manage Payouts',
                        hide:userDetails?.roles?.includes(ROLE_ADMIN) ? false : true
                    }
                },
                {
                    name: "ccf-balances",
                    list: "/ccf-balances",
                    icon: <Balance />,
                    meta: {
                        label:'CCF Balances',
                        hide:userDetails?.roles?.includes(ROLE_ADMIN) ? false : true
                    }
                },
                {
                    name: "buy-bitcoin",
                    list: "/buy-bitcoin",
                    meta:{label:'Buy Bitcoin'},
                    icon: <CurrencyBitcoin />
                },
                {
                    name: "payouts",
                    list: "/payouts",
                    show: "/payouts/show/:id",
                    icon: <AccountBalance />,
                    meta: {
                        label:"Balance"
                    }
                },
                {
                    name: "crypto_transactions",
                    list: "/transactions",
                    show: "/transactions/show/:id",
                    icon: <Receipt />,
                    meta: {
                        label:"Transactions"
                    }
                },
                {
                    name: "",
                    list: "/transactions/all",
                    icon: <ReceiptLong />,
                    meta: {
                        label:"All Transactions",
                        hide:userDetails?.roles?.includes(ROLE_ADMIN) ? false : true
                    }
                },
                {
                    name: "failed_transactions",
                    list: "/failed-transactions",
                    show:"/failed-transactions/show/:id",
                    icon: <Block />,
                    meta: {
                        label:'Failed Transactions',
                        hide:userDetails?.roles?.includes(ROLE_ADMIN) ? false : true
                    }
                },
                {
                    name: "users",
                    list: "/users",
                    show: "/users/show/:id",
                    edit: "/users/edit/:id",
                    icon: <Groups3 />,
                    meta: {
                        hide:userDetails?.roles?.includes(ROLE_ADMIN) ? false : true
                    }
                },
                {
                    name: "wallets",
                    list: "/wallets",
                    show: "/wallets/show/:id",
                    create: "/wallets/create",
                    edit: "/wallets/edit/:id",
                    icon: <GroupAdd />
                },
            ]}
              options={{
                syncWithLocation: true,
                warnWhenUnsavedChanges: true,
              }}
            >
              <Routes>
                <Route index element={<WelcomePage />} />
                <Route
                    element={
                        <Authenticated
                            fallback={
                                <CatchAllNavigate to="/login" />
                            }
                        >
                            <ThemedLayoutV2 
                                Header={() => <CustomHeader />}
                                Footer={() => (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            width: "100%",
                                            height: "64px",
                                            backgroundColor: "primary.main",
                                            color: "white",
                                        }}
                                    >
                                        2023 © Crypto Cash Fast, Inc.
                                    </Box>
                                )}
                            >
                                <Outlet />
                            </ThemedLayoutV2>
                        </Authenticated>
                    }
                >
                    <Route index element={<NavigateToResource resource="payouts" />} />
                    <Route path="payouts">
                        <Route index element={<PayoutList />} />
                        <Route path="/payouts/show/:id" element={<PayoutShow />} />
                    </Route>
                    <Route path="transactions">
                        <Route index element={<TransactionList />} />
                        <Route path="/transactions/show/:id" element={<TransactionShow />} />
                        <Route path="/transactions/all" element={<AllTransactionList />} />
                    </Route>
                    <Route path="failed-transactions">
                        <Route index element={<FailedTransactionList />} />
                        <Route path="/failed-transactions/show/:id" element={<FailedTransactionShow />} />
                    </Route>
                    <Route path="users">
                        <Route index element={<UserList />} />
                        <Route path="/users/show/:id" element={<UserShow />} />
                        <Route path="/users/edit/:id" element={<UserEdit />} />
                    </Route>
                    <Route path="wallets">
                        <Route index element={<WalletList />} />
                        <Route path="/wallets/create" element={<WalletCreate />} />
                        <Route path="/wallets/show/:id" element={<WalletShow />} />
                        <Route path="/wallets/edit/:id" element={<WalletEdit />} />
                    </Route>
                    <Route path="dashboard">
                        <Route index element={<DashboardHome />} />
                    </Route>
                    <Route path="buy-bitcoin">
                        <Route index element={<CryptoTransactionCreate />} />
                    </Route>
                    <Route path="manage-payouts">
                        <Route index element={<AdminPayouts />} />
                        <Route path="/manage-payouts/show/:id" element={<AdminPayoutsShow />} />
                    </Route>
                    <Route path="ccf-balances">
                        <Route index element={<CCFBalances />} />
                    </Route>
                    <Route path="kyc-verify">
                        <Route index element={<KYCVerify />} />
                    </Route>
                </Route>
                <Route
                    element={
                        <Authenticated fallback={<Outlet />}>
                            <NavigateToResource />
                        </Authenticated>
                    }
                >
                    <Route
                        path="/login"
                        element={<AuthPage type="login" title={<Logo />} />}
                    />
                    <Route
                        path="/login/success"
                        element={<AuthPage type="login" title={<Logo />} />}
                    />
                    <Route
                        path="/login/error"
                        element={<RegisterPage />}
                    />
                    <Route
                        path="/register"
                        element={<RegisterPage />} 
                    />
                    <Route
                        path="/forgot-password"
                        element={<AuthPage type="forgotPassword" title={<Logo />} />} 
                    />
                    <Route
                        path="/password-reset/:token"
                        element={<PasswordUpdate />} 
                    />
                </Route>
                <Route
                    element={
                        <Authenticated fallback={<Outlet />}>
                            <ThemedLayoutV2>
                                <Outlet />
                            </ThemedLayoutV2>
                        </Authenticated>
                    }
                >
                    <Route path="*" element={<ErrorComponent />} />
                </Route>
              </Routes>
              <RefineKbar />
              <UnsavedChangesNotifier />
              <DocumentTitleHandler handler={({ resource, action, params }) => customTitleHandler({ resource, action, params })} />
            </Refine>
          </RefineSnackbarProvider>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );
}

export default App;
