import React, { useContext, useEffect, useState } from "react";
import {
    useDataGrid,
    ShowButton,
    List,
    DateField,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps, useList } from "@refinedev/core";
import { Box, Button, IconButton } from "@mui/material";
import { ArrowBack, ArrowForward, Payment } from "@mui/icons-material";
import { reponsiveWidth } from "components/utils/styles";
import { UserContext } from "contexts/auth.context";
import { SendCryptoWithFee } from "components/SendCryptoWithFee";

export const FailedTransactionList: React.FC<IResourceComponentsProps> = () => {
    const [ pagesize, setPagesize] = useState(0)
    const [ records_length, setRecordsLength] = useState(0)
    const [startValue, setStartValue] = useState('') 
    const { dataGridProps } = useDataGrid({
        meta: {
            headers: { pagesize, startValue, 'special-access-token':process.env.REACT_APP_X_Access }
        },
    });

    const [btcRecieverProp, setBTCR] = useState('')
    const [btcSenderProp, setBTCS] = useState('')
    const [amountProp, setAmount] = useState('')
    const [feeProp, setFee] = useState(0)
    const [hashProp, setHash] = useState('')
    const [typeProp, setType] = useState('')

    const handleCryptoValues = (row:any) => {
        setBTCR(row.wallet[1])
        setBTCS(row.wallet[0])
        setAmount(row.currency_amount)
        setFee(0)
        setHash(row.original_hash ?? '')
        setType(row.type ?? '')
    }

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "original_hash",
                headerName: "Original Hash",
                minWidth: 50,
                renderCell: function render({ value }) {
                    if(value){
                        return ("..."+value.slice(55,))
                    }
                },
            },
            {
                field: "wallet",
                headerName: "Address",
                minWidth: 100,
                renderCell: function render({ value }) {
                    return value[0]
                },
            },
            {
                field: "details",
                flex: 1,
                headerName: "Satoshi",
                minWidth: 100,
                renderCell: function render({ value }) {
                    return JSON.stringify(value)
                },
            },
            {
                field: "currency_amount",
                flex: 1,
                headerName: "BTC Amount",
                minWidth: 100,
                renderCell: function render({ value }) {
                    return Number(value).toFixed(8)
                },
            },
            {
                field: "currency",
                flex: 1,
                headerName: "Currency",
                minWidth: 100,
                renderCell: function render({ value }) {
                    if(value === "Bitcoin"){
                        return <img width={50} src="https://storage.googleapis.com/cryptocashfast.appspot.com/Bitcoin.png" />
                    }
                    if(value === "USD"){
                        return <img width={50} src="https://storage.googleapis.com/cryptocashfast.appspot.com/usd.png" />
                    }
                },
            },
            {
                field: "payment_status",
                flex: 1,
                headerName: "Payment Status",
                minWidth: 100,
            },
            {
                field: "payout_status",
                flex: 1,
                headerName: "Payout Status",
                minWidth: 100,
            },
            {
                field: "usd_amount",
                flex: 1,
                headerName: "USD Amount",
                minWidth: 100,
                renderCell: function render({ value }) {
                    return "$"+Number(value).toFixed(2).toLocaleString();
                },
            },
            {
                field: "updatedAt",
                flex: 1,
                headerName: "Updated At",
                minWidth: 100,
                renderCell: function render({ value }) {
                    return <DateField value={new Date(value)} />;
                },
            },
            {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <ShowButton hideText recordItemId={row.id} />
                            
                            <IconButton onClick={() => handleCryptoValues(row)} color="primary">
                                <Payment />
                            </IconButton>
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],
        [],
    );

    return (
        <List>

            <SendCryptoWithFee 
                btcRecieverProp={btcRecieverProp} 
                btcSenderProp={btcSenderProp} 
                amountProp={amountProp}
                feeProp={feeProp}
                hashProp={hashProp}
                typeProp={typeProp}
            />

            <DataGrid {...dataGridProps} columns={columns} hideFooterPagination={true} autoHeight style={{maxWidth:reponsiveWidth, overflow:'auto', margin:'auto'}}  
                onStateChange={(state) => {
                    setStartValue(state.rows.dataRowIdToIdLookup[Object.keys(state.rows.dataRowIdToIdLookup)[Object.keys(state.rows.dataRowIdToIdLookup).length-1]])
                    setRecordsLength(Object.keys(state.rows.dataRowIdToIdLookup).length)
                }}  
            />
            <Box style={{display:'flex', justifyContent:'flex-end'}}>
                {pagesize !== 0 && <Button onClick={() => setPagesize(pagesize - 10)} startIcon={<ArrowBack />}></Button>}
                {records_length > 9 && <Button onClick={() => setPagesize(pagesize + 10)} startIcon={<ArrowForward />}></Button>}
            </Box>
        </List>
    );
};
