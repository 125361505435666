import { useList, useCreate  } from "@refinedev/core";
import { Grid, Card, CardContent, Typography, CardActions, Button } from '@mui/material'


export const CryptoBalances = ({slice}:any) => {

    const { data:crypto_data } = useList({
        dataProviderName: "admin",
        resource: "crypto/balance",
        queryOptions: {
            staleTime: Infinity
        },
        meta: {
            headers: { 'special-access-token':process.env.REACT_APP_X_Access }
        },
    });

    const crypto = crypto_data?.data

    const satoshiToBitcoin = (satoshi:number) => {
        return satoshi / 100000000;
    }

    const { mutate } = useCreate();

    const handleCashout = (cashoutWallet:any, type:any) => {
        
        mutate({
            dataProviderName:"admin",
            resource: "crypto-ct",
            values: {
                type, 
                cashoutWallet
            },
            meta: {
                headers: { 'special-access-token':process.env.REACT_APP_X_Access }
            },
        },
        {
            onError: (error, variables, context) => {
                // An error occurred!
                console.log(error)
            },
            onSuccess: (data, variables, context) => {
                // Let's celebrate!
                console.log(data)
            },
        },);
    }

    return (
        <>
        {crypto?.slice(0, slice).map((item, index) =>
            <Grid key={index} item>
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {item?.address}
                        </Typography>
                        <Typography variant="h5" component="div">
                        Final Balance:{satoshiToBitcoin(item?.final_balance)}
                        </Typography>
                        <Typography sx={{ mb: 1.5 }} color="text.secondary">
                        Balance: {satoshiToBitcoin(item?.balance)}
                        </Typography>
                        <Typography variant="body2">
                        Unconfirmed: {satoshiToBitcoin(item?.unconfirmed_balance)}
                        <br />
                        Type: {item?.type}
                        </Typography>
                    </CardContent>
                    {item?.type === "Customer" &&
                        <CardActions>
                            <Button onClick={() => handleCashout(item?.address, item?.type)}>Cash Out</Button>
                        </CardActions>
                    }
                    {item?.type === "Company" &&
                        <CardActions>
                            <Button onClick={() => handleCashout(item?.address, item?.type)}>Cash Out</Button>
                        </CardActions>
                    }
                </Card>
            </Grid>
        )}
        </>
    )
}