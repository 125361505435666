import { Card, TextField, Button } from '@mui/material';
import { useCreate  } from "@refinedev/core";
import { useState, useEffect } from 'react';

export const SendCryptoWithFee = (props:any) => {
    const {
        btcRecieverProp, 
        btcSenderProp, 
        amountProp, 
        feeProp,
        hashProp,  
        typeProp, 
    } = props

    const [btcRecieverListed, setBTCRecieverListed] = useState('')
    const [btcSenderListed, setBTCSenderListed] = useState('')
    const [amountListed, setAmountListed] = useState(0)
    const [feeListed, setFeeListed] = useState(0)
    const [hashListed, setHashListed] = useState('')
    const [typeListed, setTypeListed] = useState('')

    useEffect(() => {
        setBTCRecieverListed(btcRecieverProp)
        setBTCSenderListed(btcSenderProp)
        setAmountListed(amountProp)
        setFeeListed(feeProp)
        setHashListed(hashProp)
        setTypeListed(typeProp)
    }, [props])
    

    let keyFields = [
        {label:"Sender", value:btcSenderListed, setValue:setBTCSenderListed},
        {label:"Reciever", value:btcRecieverListed, setValue:setBTCRecieverListed},
        {label:"Amount", value:amountListed, setValue:setAmountListed},
        {label:"Fee", value:feeListed, setValue:setFeeListed},
        {label:"Original Hash", value:hashListed, setValue:setHashListed},
        {label:"Type", value:typeListed, setValue:setTypeListed},
    ]
    
    let inputStyle = {
        margin:5
    }

    const btcTOsats = (bitcoin:number) => {
        return bitcoin * 100000000;
    }

    const { mutate } = useCreate();

    const handleSendCrypto = () => {

        let amount = Number(btcTOsats(amountListed)).toFixed(0)
        let fee = Number(btcTOsats(feeListed)).toFixed(0)
        
        mutate({
            dataProviderName:"admin",
            resource: "crypto-w-fee",
            values: {
                fee, 
                btcSender:btcSenderListed, 
                btcReciever:btcRecieverListed, 
                amount, 
                type:typeListed, 
                original_hash:hashListed
            },
            meta: {
                headers: { 'special-access-token':process.env.REACT_APP_X_Access }
            },
        },
        {
            onError: (error, variables, context) => {
                // An error occurred!
                console.log(error)
            },
            onSuccess: (data, variables, context) => {
                // Let's celebrate!
                console.log(data)
            },
        },);
    }
    
    return (
        <Card>
            {keyFields.map((item:any, index:any) => 
                <TextField onChange={(e) => item.setValue(e.target.value)} value={item.value} key={index} style={inputStyle} required id="outlined-basic" label={item.label} variant="outlined" />
            )}
            <Button disabled={ !btcRecieverListed || !btcSenderListed || !feeListed || !amountListed } onClick={handleSendCrypto} style={inputStyle} variant='contained'>Send Crypto</Button>
        </Card>
    )
}