import { Box, LinearProgress, Typography } from "@mui/material"
import { style } from "components/utils/styles"
import { UserContext } from "contexts/auth.context"
import { useContext, useEffect } from "react"

export const KYCVerify = () => {
    const user = useContext(UserContext)

    useEffect(() => {
      if(user?.kyc){
        window.location.replace("/dashboard");
      }

      setTimeout(() => {
        window.location.reload()
      }, 4000);
    }, [user])
    

    return (
        <Box sx={style}>
            <Typography>
                Waiting for Stripe to confirm your KYC
            </Typography>
            <LinearProgress />
        </Box>
    )
}