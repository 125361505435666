import React, { useContext, useEffect, useState } from "react";
import {
    useDataGrid,
    ShowButton,
    List,
    DateField,
} from "@refinedev/mui";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { IResourceComponentsProps, useList } from "@refinedev/core";
import { Box, Button } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { reponsiveWidth } from "components/utils/styles";
import { UserContext } from "contexts/auth.context";

export const TransactionList: React.FC<IResourceComponentsProps> = () => {
    const [ pagesize, setPagesize] = useState(0)
    const [ records_length, setRecordsLength] = useState(0)
    const [startValue, setStartValue] = useState('') 
    const [wallet_list, setWalletList] = useState([])
    const user = useContext(UserContext)
    const { dataGridProps } = useDataGrid({
        meta: {
            headers: { wallet: JSON.stringify(wallet_list), pagesize, startValue, 'special-access-token':process.env.REACT_APP_X_Access }
        },
    });

    const { data: walletData, isLoading: walletIsLoading } = useList({
        resource: "wallets",
        meta: {
                headers: { page_number:0, business:user?.id, limitlisted:0, 'special-access-token':process.env.REACT_APP_X_Access }
            },
    });

    useEffect(() => {
        let local_walletList:any = []
        walletData?.data.filter(item => item.address && local_walletList.push(item.address) )
        setWalletList(local_walletList)
    }, [walletData])

    const columns = React.useMemo<GridColDef[]>(
        () => [
            {
                field: "id",
                headerName: "Id",
                minWidth: 50,
            },
            {
                field: "wallet",
                headerName: "Address",
                minWidth: 100,
                renderCell: function render({ value }) {
                    return value[0]
                },
            },
            {
                field: "details",
                flex: 1,
                headerName: "Satoshi",
                minWidth: 100,
                renderCell: function render({ value }) {
                    return value?.outputs[0].value
                },
            },
            {
                field: "currency_amount",
                flex: 1,
                headerName: "BTC Amount",
                minWidth: 100,
                renderCell: function render({ value }) {
                    return Number(value).toFixed(8)
                },
            },
            {
                field: "currency",
                flex: 1,
                headerName: "Currency",
                minWidth: 100,
                renderCell: function render({ value }) {
                    if(value === "Bitcoin"){
                        return <img width={50} src="https://storage.googleapis.com/cryptocashfast.appspot.com/Bitcoin.png" />
                    }
                    if(value === "USD"){
                        return <img width={50} src="https://storage.googleapis.com/cryptocashfast.appspot.com/usd.png" />
                    }
                },
            },
            {
                field: "payment_status",
                flex: 1,
                headerName: "Payment Status",
                minWidth: 100,
            },
            {
                field: "payout_status",
                flex: 1,
                headerName: "Payout Status",
                minWidth: 100,
            },
            {
                field: "usd_amount",
                flex: 1,
                headerName: "USD Amount",
                minWidth: 100,
                renderCell: function render({ value }) {
                    return "$"+Number(value).toFixed(2).toLocaleString();
                },
            },
            {
                field: "updatedAt",
                flex: 1,
                headerName: "Updated At",
                minWidth: 100,
                renderCell: function render({ value }) {
                    return <DateField value={new Date(value)} />;
                },
            },
            {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                renderCell: function render({ row }) {
                    return (
                        <>
                            <ShowButton hideText recordItemId={row.id} />
                        </>
                    );
                },
                align: "center",
                headerAlign: "center",
                minWidth: 80,
            },
        ],
        [],
    );

    return (
        <List>
            <DataGrid {...dataGridProps} columns={columns} hideFooterPagination={true} autoHeight style={{maxWidth:reponsiveWidth, overflow:'auto', margin:'auto'}}  
                onStateChange={(state) => {
                    setStartValue(state.rows.dataRowIdToIdLookup[Object.keys(state.rows.dataRowIdToIdLookup)[Object.keys(state.rows.dataRowIdToIdLookup).length-1]])
                    setRecordsLength(Object.keys(state.rows.dataRowIdToIdLookup).length)
                }}  
            />
            <Box style={{display:'flex', justifyContent:'flex-end'}}>
                {pagesize !== 0 && <Button onClick={() => setPagesize(pagesize - 10)} startIcon={<ArrowBack />}></Button>}
                {records_length > 9 && <Button onClick={() => setPagesize(pagesize + 10)} startIcon={<ArrowForward />}></Button>}
            </Box>
        </List>
    );
};
